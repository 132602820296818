import { makeStyles } from '@material-ui/styles';

const margins = new Map();
const root = {
	styles: detail => {
		let v = [detail.top, detail.side, detail.bottom].join('-');
		if (!margins.has(v)) {
			margins.set(v, makeStyles(theme => ({
				root: {
					paddingTop: theme.spacing(detail.top),
					paddingLeft: theme.spacing(detail.side),
					paddingRight: theme.spacing(detail.side),
					paddingBottom: theme.spacing(detail.bottom)
				}
			}), { name: `paddings-${v}`, index: 10000 }));
		}
		return margins.get(v);
	}
}

export { root };

import React, { useEffect, useCallback, useState } from 'react';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/styles';
import BoxBlock from '../../containers/box/block';

const BannerBlock = (props, ref) => {
	const [bannerNode, setBannerNode] = useState();
	const [contentHeight, setContentHeight] = useState();
	// const bannerRef = useCallback(node => {
	// 	if (node !== null) {
	// 		setRootNode(node);
	// 	}
	// }, []);
	// const slideContent = true;
	useEffect(() => {
		return;
		const [caption, title, content] = bannerNode.children;
		if (!content) return;
		setContentHeight(content.offsetHeight);
	}, [bannerNode]);
	return <BoxBlock contentHeight={contentHeight} ref={ref} setBannerCallback={setBannerNode} {...props} />;
};

export default ThemeLoader(
	StyleLoader(
		BannerBlock,
		makeStyles(theme => (
			{
				root: {
					display: 'block',
					width: '100%',
					'& img': {
						maxWidth: '100%',
					},
				},
				initFadeIn: {},
				runFadeIn: {},
				initSlideUp: {},
				runSlideUp: {},
				initTransition: {},
				slideContentContainer: {
					display: 'flex !important',
				},
				initContentSlideUp: {
					webkitTransition: 'all 0.2s ease-in-out',
					transition: 'all 0.2s ease-in-out',
					webkitTransform: 'translateY(var(--content-height))',
					transform: 'translateY(var(--content-height))',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-end',
					'&:hover': {
						webkitTransform: 'translateY(0px)',
						transform: 'translateY(0px)',
					},
				},
				runContentSlideUp: {
					webkitTransform: 'translateY(0px)',
					transform: 'translateY(0px)',
				},
				initContentTransition: {
					webkitTransition: 'all 0.6s ease-in-out',
					transition: 'all 0.6s ease-in-out',
				},
			}),
			{ name: 'SwBanner' }
		)
	)
);

// initSlideUp: {
// 	'& *': {
// 		webkitTransform: 'translateY(30px)',
// 		transform: 'translateY(30px)',
// 		'& img': {
// 			webkitTransform: 'translateY(-30px)',
// 			transform: 'translateY(-30px)',
// 		},
// 		'& [class*=SWRecordHeader-images-]': {
// 			webkitTransform: 'unset',
// 			transform: 'unset',
// 		},
// 	},
// },
// runSlideUp: {
// 	'& *': {
// 		webkitTransform: 'translateY(0px)',
// 		transform: 'translateY(0px)',
// 		'& img': {
// 			webkitTransform: 'translateY(0px)',
// 			transform: 'translateY(0px)',
// 		},
// 	},
// },
// initTransition: {
// 	'& *': {
// 		webkitTransition: 'all 0.6s ease-in-out',
// 		transition: 'all 0.6s ease-in-out',
// 	},
// },

import { makeStyles } from '@material-ui/styles';
import { StyleLoader } from '@sightworks/theme';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';

const TextBlock = ({ classes, title, content }, ref) => {
	return (
		<div className={classes.root} ref={ref}>
			<div className={classes.content} dangerouslySetInnerHTML={{ __html: content }} />
		</div>
	);
};

const Styles = makeStyles(
	theme => ({
		root: {
			wordWrap: `break-word`,
		},
		content: {
			...theme.typography.body1,
			margin: 0,
			'&:before, &:after': {
				display: 'table',
				margin: 0,
			},
			// '& > :first-child': { marginTop: 0 },
			// '& > :last-child': { marginBottom: 0 },
			'& h1, & .h1': theme.typography.h1,
			'& h2, & .h2': theme.typography.h2,
			'& h3, & .h3': theme.typography.h3,
			'& h4, & .h4': theme.typography.h4,
			'& h5, & .h5': theme.typography.h5,
			'& h6, & .h6': theme.typography.h6,
			'& .body1': theme.typography.body1,
			'& .body2': theme.typography.body2,
			'& .caption': theme.typography.caption,
			'& .button': theme.typography.button,
			'& .subtitle1': theme.typography.subtitle1,
			'& .subtitle2': theme.typography.subtitle2,
			'& .overline': theme.typography.overline,
			'& p > img': {
				display: 'flex',
				float: 'none !important',
				margin: 'auto',
				marginTop: theme.spacing(2),
				marginBottom: theme.spacing(2),
			},
		},
	}),
	{ name: 'SWArticle-Text' }
);

export default StyleLoader(TextBlock, Styles);

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import React, { useMemo, useEffect, createContext, useContext } from 'react';

const BPContext = createContext('md');

function useBaseBreakpoint(defaultValue = 'md') {
	const theme = useTheme();
	const breakpoints = useMemo(() => {
		return ['xs', 'sm', 'md', 'lg', 'xl'].reduce(
			(acc, val) => ({ ...acc, [val]: theme.breakpoints.only(val) }),
			{}
		);
	}, [theme]);
	const allBps = Object.entries(breakpoints).map(([bp, q]) => [
		bp,
		useMediaQuery(q),
	]);
	const matchedBreakpoint = allBps.find(([bp, matched]) => matched);
	// console.log({ allBps, matchedBreakpoint });
	return (matchedBreakpoint || [defaultValue])[0];
}

const Breakpoint = ({ initialValue, children }) => {
	const currentBp = useBaseBreakpoint(initialValue);
	useEffect(() => {
		document.cookie = `breakpoint=${encodeURIComponent(currentBp)}; path=/`;
	}, [currentBp]);
	return <BPContext.Provider value={currentBp}>{children}</BPContext.Provider>;
}

export { Breakpoint };

export default function useBreakpoint() {
	const bp = useContext(BPContext);
	return bp;
}

import RawBlocks from './raw/browser';
import CookedBlocks from './cooked/browser';
import ContainerBlocks from './containers/browser';
import CompoundBlocks from './compound/browser';
import SpecialBlocks from './special/browser';
import ArticleBlocks from './article/browser';
import Customizations from './customization/browser';
import RecordBlocks from './records/browser';
import * as Registry from './registry';

export default {
	...RawBlocks,
	...CookedBlocks,
	...ContainerBlocks,
	...CompoundBlocks,
	...SpecialBlocks,
	...ArticleBlocks,
	...RecordBlocks,
};

export { Registry, Customizations };

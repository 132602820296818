// Load it from the current package.
import { getThemeModule, loadThemeModule } from '@sightworks/theme';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { createMuiTheme, useTheme } from '@material-ui/core/styles';
import React, { Component, Fragment, useState, useEffect } from 'react';
import { Registry } from '@sightworks/block';
import clsx from 'clsx';

const updateTheme = Symbol('updateTheme');
const setTheme = Symbol('setTheme');
const initTheme = Symbol('initTheme');
const renderBase = Symbol('render');

const useGlobalPageStyles = makeStyles(
	theme => ({
		root: {
			'--divider': `1px solid ${theme.palette.divider}`,
			[theme.breakpoints.only('xs')]: {
				'--width-small': '80%',
				'--width-medium': '85%',
				'--width-large': '90%',
				'--margin-small': `${theme.spacing(1)}px`,
				'--margin-medium': `${theme.spacing(2)}px`,
				'--margin-large': `${theme.spacing(4)}px`,
			},
			[theme.breakpoints.up('sm')]: {
				'--width-small': '60%',
				'--width-medium': '80%',
				'--width-large': '90%',
				'--margin-small': `${theme.spacing(1)}px`,
				'--margin-medium': `${theme.spacing(4)}px`,
				'--margin-large': `${theme.spacing(8)}px`,
			},
			'& a[href^="tel:"]': {
				textDecoration: 'none',
				color: 'inherit'
			}
		},
		withWidth: {
			marginLeft: 'auto',
			marginRight: 'auto',
			width: 'var(--selected-width)',
		},
		withSmallWidth: {
			width: 'var(--width-small, 80%)',
		},
		withMediumWidth: {
			width: 'var(--width-medium, 85%)',
		},
		withLargeWidth: {
			width: 'var(--width-large, 90%)',
		},
		withTopMargin: {
			marginTop: `var(--computed-top-margin)`,
		},
		withBottomMargin: {
			marginBottom: `var(--computed-bottom-margin)`,
		},
		withTopDivider: {
			'--computed-top-divider': `var(--divider, 1px solid ${theme.palette.divider})`,
			borderTop: `var(--computed-top-divider)`,
			'&$withTopMargin': {
				marginTop: 0,
				paddingTop: 'var(--computed-top-margin)',
			},
		},
		withBottomDivider: {
			'--computed-bottom-divider': `var(--divider, 1px solid ${theme.palette.divider})`,
			borderBottom: `var(--computed-bottom-divider)`,
			'&$withBottomMargin': {
				marginBottom: 0,
				paddingBottom: 'var(--computed-bottom-margin)',
			},
			'& + $withTopDivider': {
				borderTop: 'none',
			},
		},
		withSmallTopMargin: {
			'--computed-top-margin': `var(--margin-small, ${theme.spacing(1)}px)`,
		},
		withMediumTopMargin: {
			'--computed-top-margin': `var(--margin-medium, ${theme.spacing(2)}px)`,
		},
		withLargeTopMargin: {
			'--computed-top-margin': `var(--margin-large, ${theme.spacing(4)}px)`,
		},
		withSmallBottomMargin: {
			'--computed-bottom-margin': `var(--margin-small, ${theme.spacing(1)}px)`,
		},
		withMediumBottomMargin: {
			'--computed-bottom-margin': `var(--margin-medium, ${theme.spacing(2)}px)`,
		},
		withLargeBottomMargin: {
			'--computed-bottom-margin': `var(--margin-large, ${theme.spacing(4)}px)`,
		},
	}),
	{
		name: 'SwGlobal',
		index: 10000,
	}
);

export default function ThemeLoader(parent, defaultTheme = null, defaultNamedTheme = null) {
	let target = parent;
	if (parent.length == 2) target = React.forwardRef(parent);

	const UseGlobalStyles = ({ theme, ...rest }) => {
		return (
			<ThemeProvider theme={theme}>
				<UseGlobalStylesInner {...rest} />
			</ThemeProvider>
		);
	};

	const ApplyBoxProperties = ({ boxProperties, component, targetProps, targetRef, targetClassName }) => {
		const { classes, className, ...rest } = targetProps;
		const theme = useTheme();
		// console.log(theme.globalStyles, boxProperties);
		boxProperties = boxProperties || {
			width: null,
			margins: {},
			dividers: {},
		};

		const root = clsx(
			targetClassName,
			className,
			(classes || {}).root,
			theme.globalStyles.width[boxProperties.width] || '',
			theme.globalStyles.margins.top[boxProperties.margins.top] || '',
			theme.globalStyles.margins.bottom[boxProperties.margins.bottom] || '',
			{
				[theme.globalStyles.dividers.top]: boxProperties.dividers.top,
				[theme.globalStyles.dividers.bottom]: boxProperties.dividers.bottom,
			}
		);

		const targetClasses = { ...(classes || {}), root };
		return React.createElement(component, {
			...rest,
			classes: targetClasses,
			className: root,
			ref: targetRef,
		});
	};

	const UseGlobalStylesInner = props => {
		const currentTheme = useTheme();
		const globalStyles = useGlobalPageStyles();
		currentTheme.globalStyles = {
			base: {
				width: globalStyles.withWidth,
				marginTop: globalStyles.withTopMargin,
				marginBottom: globalStyles.withBottomMargin,
				dividerTop: globalStyles.withTopDivider,
				dividerBottom: globalStyles.withBottomDivider,
				width: globalStyles.withWidth
			},
			width: {
				small: clsx(globalStyles.withWidth, globalStyles.withSmallWidth),
				medium: clsx(globalStyles.withWidth, globalStyles.withMediumWidth),
				large: clsx(globalStyles.withWidth, globalStyles.withLargeWidth),
			},
			margins: {
				top: {
					small: clsx(globalStyles.withTopMargin, globalStyles.withSmallTopMargin),
					medium: clsx(globalStyles.withTopMargin, globalStyles.withMediumTopMargin),
					large: clsx(globalStyles.withTopMargin, globalStyles.withLargeTopMargin),
				},
				bottom: {
					small: clsx(globalStyles.withBottomMargin, globalStyles.withSmallBottomMargin),
					medium: clsx(globalStyles.withBottomMargin, globalStyles.withMediumBottomMargin),
					large: clsx(globalStyles.withBottomMargin, globalStyles.withLargeBottomMargin),
				},
			},
			dividers: {
				top: globalStyles.withTopDivider,
				bottom: globalStyles.withBottomDivider,
			},
		};

		return <ApplyBoxProperties {...props} targetClassName={globalStyles.root} />;
	};

	const Loader = (props, ref) => {
		const currentTheme = useTheme();
		const namedTheme = props.useNamedTheme
			? Registry.makeNamedTheme(props.useNamedTheme, currentTheme)
			: (defaultNamedTheme ? Registry.makeNamedTheme(defaultNamedTheme, currentTheme) : currentTheme);

		const prepare = theme => {
			if (typeof theme === 'function') {
				theme = theme(namedTheme || {});
			}
			theme = createMuiTheme(theme);
			return theme;
		};

		const [theme, setTheme] = useState(
			props.useTheme ? prepare(getThemeModule(props.useTheme).default) : Loader.defaultTheme
		);

		const updateTheme = () => {
			if (props.useTheme) {
				const themeModule = getThemeModule(props.useTheme);
				if (!themeModule) {
					loadThemeModule(props.useTheme).then(themeModule => setTheme(prepare(themeModule.default)));
				} else {
					setTheme(prepare(themeModule.default));
				}
			} else {
				setTheme(defaultTheme);
			}
		};

		useEffect(() => {
			updateTheme();
		}, [props.useTheme]);

		const { useTheme: _, useNamedTheme: __, boxProperties, ...rest } = props;
		let inner;
		// let inner = React.createElement(target, { ...rest, ref });
		if (theme || Loader.defaultTheme) {
			inner = (
				<UseGlobalStyles
					theme={theme || Loader.defaultTheme}
					component={target}
					targetProps={rest}
					targetRef={ref}
					boxProperties={boxProperties}
				/>
			);
		} else if (namedTheme != currentTheme) {
			inner = (
				<UseGlobalStyles
					theme={namedTheme}
					component={target}
					targetProps={rest}
					targetRef={ref}
					boxProperties={boxProperties}
				/>
			);
		} else if (boxProperties) {
			inner = (
				<ApplyBoxProperties boxProperties={boxProperties} component={target} targetProps={rest} targetRef={ref} />
			);
		} else {
			inner = React.createElement(target, { ...rest, ref });
		}

		return inner;
	};

	Loader.displayName = `ThemeLoader(${parent.displayName || parent.name || 'Anonymous'})`;
	Loader.defaultTheme = defaultTheme;
	const R = React.forwardRef(Loader);
	Object.defineProperty(R, 'defaultTheme', {
		get() {
			return Loader.defaultTheme;
		},
		set(v) {
			Loader.defaultTheme = v;
		},
	});
	return R;
}

import React, { Component, useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { StyleLoader } from '@sightworks/theme';
import Styled from '../../utils/styled';
import getChildren from '../../utils/children';

const RawContainerBlock = (props, ref) => (
	<Container {...props.container} classes={props.classes} ref={ref}>
		{getChildren(props.content)}
	</Container>
);

export default StyleLoader(RawContainerBlock);

import Freeform from './freeform/browser';
import Icon from './icon/browser';
import Image from './image/browser';
import Plaintext from './plaintext/browser';
import Embed from './embed/browser';
import Chip from './chip/browser';

export default {
	freeform: Freeform,
	icon: Icon,
	image: Image,
	plaintext: Plaintext,
	embed: Embed,
	chip: Chip,
};

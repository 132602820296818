// Load it from the current package.
/* eslint-disable */
import { getThemeModule as getStyleModule, loadThemeModule as loadStyleModule } from '@sightworks/theme';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import React, { Component, Fragment, useState, useEffect, useRef, useCallback } from 'react';
import { Registry, Customizations } from '@sightworks/block';
import BackgroundLoader from './background';
import Typography from '@material-ui/core/Typography';

function merge(startClasses = {}, addClasses = {}) {
	let R = {};
	for (let [name, value] of Object.entries(startClasses)) {
		R[name] = new Set(value.split(/\s+/).filter(v => !!v));
	}
	for (let [name, value] of Object.entries(addClasses)) {
		R[name] = (R[name] || new Set()).add(value);
	}
	let S = {};
	for (let [name, value] of Object.entries(R)) S[name] = [...value].join(' ');
	return S;
}

const AccessibleRoot = props => {
	let [root, setRoot] = useState('');
	useEffect(() => {
		if (typeof location == 'object') setRoot(location.pathname + location.search);
	});
	let tgt = useRef();
	let ref = useCallback(node => tgt.current = node);
	let go = useCallback(event => { event && event.preventDefault(); setTimeout(() => tgt.current.focus(), 0) });
	return (
		<>
			<Typography variant="srOnly" component="a" href={`${root}#after-${props.id}`} onClick={go}>{props.title}</Typography>
			{props.children}
			<span id={`after-${props.id}`} ref={ref} tabIndex="0" />
		</>
	);
}

let LoadedStyleMap = new Map();

export default function StyleLoader(parent, useDefault = null) {
	let target = parent;
	if (parent.length == 2) target = React.forwardRef(parent);

	let WithStyles = (props, ref) => {
		let { useDefault, useNamedStyles, useStyles, backgroundImage, withCustomizations, withAccessible, ...rest } = props;
		let cl = [ rest.classes || {} ];
		let { classes, ...R } = props;
		if (useDefault) {
			cl.push(useDefault(R));
		}
		if (useNamedStyles) {
			cl.push(useNamedStyles(R));
		}
		if (useStyles) {
			cl.push(useStyles(R));
		}
		rest.classes = cl.reduce((a, b) => merge(a, b), {});
		rest.ref = ref;

		if (withAccessible) {
			rest.withCustomizations = withCustomizations;
			return <AccessibleRoot {...withAccessible} id={rest.id}><WithStyles {...rest}/></AccessibleRoot>
		}

		if (withCustomizations && withCustomizations.length) {
			let C = withCustomizations[0];
			rest.withCustomizations = withCustomizations.slice(1);
			let S = Customizations[C.name][C.target];
			if (S.styles) {
				rest.useStyles = S.styles(C.detail);
			}
			if (S.component) {
				if (!rest.useComponents) rest.useComponents = [];
				rest.useComponents.push({ component: S.component, detail: C.detail });
			}
			return React.createElement(WithStyles, rest);
		} else if (rest.useComponents && rest.useComponents.length) {
			let next = props => {
				let v = props.useComponents[0];
				if (v) {
					return React.createElement(v.component, { target: { ...props, useComponents: props.useComponents.slice(1) }, detail: v.detail, next });
				}
				return React.createElement(target, props);
			}
			return next(rest);
		} else {
			let { useComponents, ...R } = rest;
			return React.createElement(target, R);
		}
	}

	WithStyles.displayName = `WithStyles(${parent.displayName || parent.name || 'Anonymous'})`;
	WithStyles = React.forwardRef(WithStyles);

	let Loader = (props, ref) => {
		let prepare = module => {
			if (module) {
				let r = LoadedStyleMap.get(module);
				if (!r) {
					r = makeStyles(module.default, { name: props.useStyles.moduleId });
					LoadedStyleMap.set(module, r);
				}
				return { sheet: r };
			}
			return { sheet: null }
		}

		let [ styles, setStyles ] = useState(prepare(props.useStyles ? getStyleModule(props.useStyles) : null));
		let namedStyles = null;
		if (props.useNamedStyles) namedStyles = Registry.makeNamedStyle(props.useNamedStyles);

		let updateStyles = () => {
			if (props.useStyles) {
				let styleModule = getStyleModule(props.useStyles);
				if (!styleModule) {
					loadStyleModule(props.useStyles).then(styleModule => setStyles(prepare(styleModule)));
				} else {
					setStyles(prepare(styleModule));
				}
			} else {
				setStyles(prepare(null));
			}
		}

		useEffect(() => {
			updateStyles();
		}, [ props.useStyles ]);

		let { useStyles, useNamedStyles, ...rest } = props;
		let keyValue = "normal";
		if (useStyles && styles.sheet && useNamedStyles) keyValue = "both";
		else if (useStyles && styles.sheet) keyValue = "custom";
		else if (useNamedStyles) keyValue = 'named';
		let typeRef = useRef(keyValue)
		useEffect(() => {
			if (keyValue != typeRef.current) {
				console.log(`Type of style ref for node changed`, props, typeRef.current, keyValue);
				typeRef.current = keyValue;
			}
		}, [ keyValue ]);
		return <WithStyles {...rest} ref={ref} useNamedStyles={namedStyles} useStyles={styles.sheet} useDefault={useDefault} key={keyValue} />;
	};

	Loader.displayName = `StyleLoader(${target.displayName || parent.name || 'Anonymous'})`;
	return BackgroundLoader(Loader);
}

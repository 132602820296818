import React from 'react';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/styles';
import RawBoxBlock from '../../raw/box/block';

const BoxBlock = (props, ref) => {
	const { verticalCenter, classes } = props;
	if (verticalCenter) {
		return (
			// <div className={classes.wrapper}>
				<RawBoxBlock {...props} ref={ref} />
			// </div>
		);
	}
	return <RawBoxBlock {...props} ref={ref} />;
};
export default ThemeLoader(
	StyleLoader(
		BoxBlock,
		makeStyles(theme => ({
			wrapper: {
				display: 'flex',
				alignItems: 'center',
				height: '100%',
				'& [class*=MuiBox-root-]': {
					width: '100%',
				},
			},
		}))
	)
);

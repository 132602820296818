import React, { createContext, useState, useEffect, useMemo, useContext, useCallback } from 'react';
import { Context as QueryContext } from './query';
import { Context as NavigatorContext } from './navigator';

const LinkContext = createContext({
	open: () => {},
});

export { LinkContext as Context };

const Provider = ({ open, children }) => {
	return <LinkContext.Provider value={{ open }}>{children}</LinkContext.Provider>;
};

export default Provider;

export function useLinkAttributes(link) {
	const queryContext = useContext(QueryContext);
	const linkContext = useContext(LinkContext);
	const navContext = useContext(NavigatorContext);
	const callback = useCallback(
		event => {
			event.preventDefault();
			navContext.navigate(event.currentTarget.href);
		},
		[navContext]
	);

	if (link) {
		if (link.context) {
			return { onClick: linkContext.open };
		}

		if (link.submit) {
			return { type: 'submit' };
		}

		if (link.internal) {
			return { onClick: link.handler };
		}

		const lp = {};
		lp.href = link.preserveQuery ? queryContext.extend(link.href) : link.href;
		if (link.openInNewWindow) lp.target = '_blank';
		if (link.ajaxLoad) lp.onClick = callback;
		return lp;
	}

	return {};
}

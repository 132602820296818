import React, { createContext, useState, useEffect, useMemo, useContext } from 'react';
import getChildren from './children';
import * as Registry from '../registry';
import { Context as FilterContext } from '../containers/filter/context';
import { useTheme } from '@material-ui/styles';

const RecordContext = createContext({
	loading: null
});

export { RecordContext as Context };

const Provider = props => {
	let [ isLoading, setIsLoading ] = useState(false);
	let [ content, setContent ] = useState(props.content);
	let { targets } = props;
	
	useEffect(() => {
		setIsLoading(false)
	}, [ props ]);

	useEffect(() => {
		let content = deepClone(props.content);
		content.forEach(node => setLoading(node, targets, isLoading))
		setContent(content);
	}, [ props.content, targets, isLoading ]);

	const filterContext = useContext(FilterContext);
	const theme = useTheme();
	
	let providerState = useMemo(() => ({
		isLoading,
		setLoading: async () => {
			setIsLoading(true);
			await new Promise(resolve => { setTimeout(() => resolve(true), theme.transitions.duration.standard); });
		},
		targets
	}), [ targets, isLoading ]);

	useEffect(() => {
		if (props.useForFilter && filterContext && filterContext.recordList != providerState) {
			filterContext.setRecordList(providerState);
		}
		return () => {
			if (filterContext && filterContext.recordList == providerState) filterContext.setRecordList(null);
		};
	}, [ filterContext, providerState, props.useForFilter ]);
	
	return (
		<RecordContext.Provider value={providerState}>{getChildren(content)}</RecordContext.Provider>
	);
}

function deepClone(node) {
	// Hack, but it works for now.
	return JSON.parse(JSON.stringify(node));
}

function setLoading(childNode, loadingTargets, loadingState) {
	if (loadingTargets.indexOf(childNode.id) != -1) {
		childNode.isLoading = loadingState;
	}

	Registry.getChildren(childNode).forEach(node => setLoading(node, loadingTargets, loadingState));
}

export default Provider;

import React, { Component, useState, useEffect, useRef } from 'react';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/styles';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';

const GoogleSearchBox = ({ classes, link }, ref) => {
	const [open, setOpen] = useState(false);
	const cx = '017271925464308991600:k6cmzkahqip';
	const inputEl = useRef(null);

	const handleOpen = () => {
		setOpen(true);
		inputEl.current.focus();
	};

	const handleClose = e => {
		if (e.target.value.length > 1) return;
		setOpen(false);
	};

	const handleSubmit = e => {
		e.preventDefault();
		const params = { q: inputEl.current.value };
		const paramString = new URLSearchParams(params);
		window.location.assign(`${link.href || location.pathname}?${paramString}`);
	};

	return (
		<>
			<form className={clsx(classes.root, { [classes.rootOpen]: open })} onSubmit={handleSubmit}>
				<IconButton className={classes.iconButton} aria-label="search" onClick={handleOpen}>
					<SearchIcon />
				</IconButton>
				<InputBase
					className={clsx(classes.input, { [classes.inputOpen]: open })}
					placeholder="Search"
					inputProps={{
						'aria-label': 'search the german international school',
					}}
					onBlur={handleClose}
					inputRef={inputEl}
				/>
				<input type="hidden" id="cx" name="cx" value={cx} />
			</form>
			<div className={classes.placeholder} />
		</>
	);
};

export default ThemeLoader(
	StyleLoader(
		GoogleSearchBox,
		makeStyles(
			theme => ({
				root: {
					padding: '2px 4px',
					display: 'flex',
					alignItems: 'center',
					marginRight: theme.spacing(1),
					marginLeft: theme.spacing(1),
					'& .MuiInputBase-input': {
						width: 'inherit',
					},
					[theme.breakpoints.down('xs')]: {
						position: 'absolute',
						right: '0px',
						height: '100%',
						background: '#ffffff',
					},
					minWidth: '0px',
					width: '40px',
					transition: 'width 300ms',
					position: 'absolute',
					right: '0px',
					height: '100%',
					background: '#ffffff',
				},
				input: {
					flex: 1,
					width: 0,
				},
				iconButton: {
					padding: 10,
					color: theme.palette.primary.main,
				},
				rootOpen: {
					[theme.breakpoints.only('xl')]: {
						width: '26%',
					},
					[theme.breakpoints.only('lg')]: {
						width: '38%',
					},
					[theme.breakpoints.only('md')]: {
						width: '25%',
					},
					[theme.breakpoints.only('sm')]: {
						width: `40%`,
					},
					[theme.breakpoints.down('xs')]: {
						width: '90%',
					},
					[theme.breakpoints.down('420')]: {
						width: '90%',
					},
				},
				inputOpen: {
					width: '100%',
				},
				openMobileRootWidth: {
					[theme.breakpoints.down('xs')]: {
						width: '90%',
					},
				},
				placeholder: {
					width: '40px',
					marginLeft: theme.spacing(1),
					marginRight: theme.spacing(1),
				},
			}),
			{ name: 'SWGoogleSearch' }
		)
	)
);

import React, { useContext, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import { StyleLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/styles';
import Styled from '../../utils/styled';
import * as Registry from '../../registry';
import getChildren from '../../utils/children';
import { useLinkAttributes } from '../../utils/linkContext';

const RawButtonBlock = ({ button, link, classes, content }, ref) => {
	const lp = useLinkAttributes(link);

	return (
		<Button ref={ref} {...button} {...lp} classes={classes}>
			{getChildren(content)}
		</Button>
	);
};

export default StyleLoader(
	RawButtonBlock,
	makeStyles(
		theme => ({
			root: {
				textTransform: 'none',
			},
			label: {
				'& > [class*=MuiIcon-root]:first-child': {
					marginLeft: theme.spacing(-1),
					marginRight: theme.spacing(1),
				},
				'& > [class*=MuiIcon-root]:last-child': {
					marginLeft: theme.spacing(1),
					marginRight: theme.spacing(-1),
				},
			},
		}),
		{ name: 'SWButton' }
	)
);

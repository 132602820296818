import React, { useState, useMemo, useCallback, createContext, useEffect, useReducer } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { StyleLoader } from '@sightworks/theme';
import Skeleton from '@material-ui/lab/Skeleton';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Slider from 'react-slick';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

const GalleryContext = createContext(null);
export { GalleryContext as Context };

function galleryReducer(state, { type, ...action }) {
	// console.log({ type, action });
	
	switch (type) {
		case 'open':
			return { ...state, open: true };
		case 'close':
			return { ...state, open: false };
		case 'toggle':
			return { ...state, open: !state.open, selectedIndex: 'selectedIndex' in action ? action.selectedIndex : state.selectedIndex };
		case 'set-carousel':
			return { ...state, carousel: action.carousel };
		case 'set-selected-index':
			return { ...state, selectedIndex: Math.max(0, Math.min(state.elements.length - 1, action.selectedIndex)) };
		case 'set-loading':
			return { ...state, imageLoading: true };
		case 'clear-loading':
			return { ...state, imageLoading: false };
		case 'register':
			let p = state.elements.indexOf(action.element);
			if (p == -1) {
				p = state.elements.length;
				state = { ...state, elements: [ ...state.elements, action.element ] };
			}
			if (action.callback) {
				action.callback(p);
			}
			return state;
		case 'reset':
			return {
				open: false,
				carousel: null,
				selectedIndex: 0,
				elements: [],
				imageLoading: true
			};

		default:
			console.log(`Invalid action ${type}:`, action);
			return state;
	}
}

const GalleryContainer = props => {
	const [ state, dispatch ] = useReducer(galleryReducer, {}, () => galleryReducer(void 0, { type: 'reset' }));
	const signalLoad = useCallback(() => { dispatch({ type: 'clear-loading' }); }, []);
	const toggle = useCallback(
		(index = 0) => { dispatch({ type: 'toggle', selectedIndex: index }); },
		[]
	);
	const register = useCallback(
		(item, indexCallback) => { dispatch({ type: 'register', element: item, callback: indexCallback }) },
		[]
	);
	const lightBox = useMemo(
		() => ({
			open: state.open,
			dispatch,
			toggle,
			register,
			state
		}),
		[state]
	);

	return (
		<GalleryContext.Provider value={lightBox}>
			{props.children}
		</GalleryContext.Provider>
	);
};

export default GalleryContainer;

import React, { Component, useState, useEffect } from 'react';
import getChildren from '../../utils/children';
import Lightbox from './context';

const GalleryContainerBlock = (props, ref) => {
	const { content } = props;
	return <Lightbox {...props}>{getChildren(content)}</Lightbox>;
};

export default GalleryContainerBlock;

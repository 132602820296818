import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const DialogActions = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2)
	}
}))(({ onClose, children, classes, ...rest }) => {
	if (React.Children.count(children)) {
		return (
			<MuiDialogActions className={classes.root} {...rest}>
				{children}
				{onClose && (
					<Typography variant="srOnly" component="a" onClick={onClose}>Close</Typography>
				)}
			</MuiDialogActions>
		);
	}

	if (onClose) {
		return <Typography variant="srOnly" component="a" onClick={onClose}>Close</Typography>
	}

	return null;
});

export default DialogActions;


import { makeStyles } from '@material-ui/styles';

const images = new Map();
const root = {
	styles: detail => {
		if (!images.has(detail.maxWidth)) {
			images.set(detail.maxWidth, makeStyles(theme => ({
				root: {
					maxWidth: detail.maxWidth
				}
			}), { name: `image-width-${detail.maxWidth}`, index: 10000 }));
		}
		return images.get(detail.maxWidth)
	}
}

export { root };

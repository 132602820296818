import React, { Component, useState, useEffect } from 'react';
import { StyleLoader } from '@sightworks/theme';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import Styled from '../../utils/styled';
import getChildren from '../../utils/children';

const ArticleBaseBlock = ({ content, topMargin, bottomMargin, itemWidth, topDivider, bottomDivider, classes }, ref) => {
	return (
		<div
			className={clsx(classes.root, {
				[classes.topMarginSmall]: topMargin == 'Small',
				[classes.topMarginMedium]: topMargin == 'Medium',
				[classes.topMarginLarge]: topMargin == 'Large',
				[classes.bottomMarginSmall]: bottomMargin == 'Small',
				[classes.bottomMarginMedium]: bottomMargin == 'Medium',
				[classes.bottomMarginLarge]: bottomMargin == 'Large',
				[classes.sideMarginNone]: itemWidth == 'Full',
				[classes.sideMarginSmall]: itemWidth == 'Large',
				[classes.sideMarginMedium]: itemWidth == 'Medium',
				[classes.sideMarginLarge]: itemWidth == 'Small',
				[classes.topDivider]: !!Number(topDivider),
				[classes.bottomDivider]: !!Number(bottomDivider),
			})}
			ref={ref}
			data-top-margin={topMargin}
			data-bottom-margin={bottomMargin}
			data-item-width={itemWidth}
		>
			{getChildren(content)}
		</div>
	);
};

const Styles = makeStyles(
	theme => ({
		root: {
			'--top-size': 0,
			'--bottom-size': 0,
			'--side-size': `${theme.spacing(2)}px`,
			marginTop: 'var(--top-size, 0px)',
			marginBottom: 'var(--bottom-size, 0px)',
			marginLeft: 'var(--side-size, 0px)',
			marginRight: 'var(--side-size, 0px)',
			'& iframe': {
				margin: 'auto',
			},
			'& img': {
				borderRadius: theme.shape.borderRadius,
			},
			'& a': {
				textDecoration: 'none',
				color: theme.palette.primary.light,
				transition: theme.transitions.create('color'),
				'&:hover': {
					color: theme.palette.primary.dark,
					textDecoration: 'underline',
				},
				'&.secondary': {
					color: theme.palette.secondary.main,
					'&:hover': {
						color: theme.palette.secondary.dark,
						textDecoration: 'underline',
					},
				},
			},
		},

		topMarginSmall: { '--top-size': `${theme.spacing(2)}px` },
		topMarginMedium: { '--top-size': `${theme.spacing(6)}px` },
		topMarginLarge: { '--top-size': `${theme.spacing(12)}px` },

		bottomMarginSmall: { '--bottom-size': `${theme.spacing(2)}px` },
		bottomMarginMedium: { '--bottom-size': `${theme.spacing(6)}px` },
		bottomMarginLarge: { '--bottom-size': `${theme.spacing(12)}px` },

		sideMarginNone: { '--side-size': 0, maxWidth: '100%' },
		sideMarginSmall: { maxWidth: '100%' },
		sideMarginMedium: { '--side-size': 'auto', maxWidth: '75%' },
		sideMarginLarge: { '--side-size': 'auto', maxWidth: '66.67%' },

		topDivider: {
			borderTop: '1px solid #666',
			marginTop: 0,
			paddingTop: 'var(--top-size, 0px)',
		},
		bottomDivider: {
			borderBottom: '1px solid #666',
			marginBottom: 0,
			paddingBottom: 'var(--bottom-size, 0px)',
			'& + $topDivider': {
				marginTop: '-1px',
			},
		},
	}),
	{ name: 'ArticleBlock' }
);

export default StyleLoader(ArticleBaseBlock, Styles);

import React, { Component, useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { StyleLoader } from '@sightworks/theme';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import Styled from '../../utils/styled';
import getChildren from '../../utils/children';
import RawIcon from '../icon/block';

const RawAvatarBlock = ({ size, variant, img: { src, alt, imgProps }, classes, icon, title }, ref) => {
	const initialTitle = avatarTitle => {
		const initialsArray = avatarTitle.split(' ').map(name => name[0].toUpperCase());
		return `${initialsArray[0]}${initialsArray[initialsArray.length - 1]}`;
	};

	const avatarContent = () => {
		if (src) return '';
		if (icon) return <RawIcon content={icon} />;
		if (title) return initialTitle(title);
		return '';
	};

	return (
		<Avatar variant={variant} src={src} alt={alt} imgProps={imgProps} ref={ref} style={{ '--size': size }}>
			{avatarContent}
		</Avatar>
	);
};

export default StyleLoader(
	RawAvatarBlock,
	makeStyles(
		theme => ({
			root: {
				height: 'var(--size)',
				width: 'var(--size)',
			},
		}),
		{ name: 'SWRawAvatar' }
	)
);

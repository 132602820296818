const boxShadowToTextShadow = shadow => {
	if (shadow == 'none') return shadow;
	let m = /\s*(\d+)px\s+(\d+)px\s+(\d+)px\s+rgba\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*,\s*([0-9.]+)\s*\)/g;
	let s = [], r;
	while (r = m.exec(shadow)) {
		s.push(`${r[1]}px ${r[2]}px rgba(${r[4]},${r[5]},${r[6]},${r[7]})`);
	}
	return s.join(', ');
}

export default theme => ({
	root: {
		'& .MuiGrid-item': {
			position: 'relative',
			paddingTop: '50%'
		},
		'& .MuiButton-root': {
			position: 'absolute',
			top: theme.spacing(0.5),
			left: theme.spacing(0.5),
			right: theme.spacing(0.5),
			bottom: theme.spacing(0.5),
			borderRadius: 2 * theme.shape.borderRadius,
			borderWidth: theme.spacing(1),
			borderStyle: 'solid',
			borderColor: theme.palette.common.white,
			padding: theme.spacing(2),
			textAlign: 'center',
			alignItems: 'center',
			justifyContent: 'center',
			'& > *': {
				position: 'relative',
				zIndex: 1
			},
			'&:before': {
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				// borderRadius: 2 * theme.shape.borderRadius,
				backgroundColor: 'rgba(0, 0, 0, 0.4)',
				content: '""',
				transition: theme.transitions.create('background-color')
			},
			'& .MuiTypography-root': {
				transition: theme.transitions.create([ 'color', 'text-shadow' ]),
				textShadow: boxShadowToTextShadow(theme.shadows[2]),
				[theme.breakpoints.only('xs')]: {
					fontSize: '1.25rem',
				}
			},
			'&:hover': {
				'&:before': {
					backgroundColor: '#fff',
				},
				'& .MuiTypography-root': {
					color: theme.palette.text.primary,
					textShadow: 'none'
				}
			}
		}
	}
})

import React, { useState } from 'react';
import Icon from '@material-ui/core/Icon';
import { StyleLoader } from '@sightworks/theme';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Styled from '../../utils/styled';

const RawIconBlock = ({ classes, content, color, test }, ref) => {
	const iconComponents = {
		facebook: FacebookIcon,
		instagram: InstagramIcon,
		twitter: TwitterIcon,
		youtube: YouTubeIcon,
		linkedIn: LinkedInIcon,
		default: Icon,
	};

	const Component = (() => {
		const name = content.split('svg:')[1] || 'default';
		return iconComponents[name];
	})();

	const IconBlock = (
		<Component
			className={clsx(classes.root, {
				[classes.default]: color === 'default',
				[classes.primary]: color === 'primary',
				[classes.secondary]: color === 'secondary',
			})}
			ref={ref}
		>
			{content.startsWith('svg:') ? null : content}
		</Component>
	);

	return IconBlock;
};

export default StyleLoader(
	RawIconBlock,
	makeStyles(
		theme => ({
			root: {},
			default: {
				color: theme.palette.text.primary,
			},
			primary: {
				color: theme.palette.primary.main,
			},
			secondary: {
				color: theme.palette.secondary.main,
			},
		}),
		{ name: 'SWIcon' }
	)
);

import React, { useContext, useState, useCallback, useEffect, useRef } from 'react';
import { Context } from '../gallery-container/context';
import clsx from 'clsx';
import imageIsCached from '../../utils/imageIsCached';
import Skeleton from '@material-ui/lab/Skeleton';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Animation from '@sightworks/transition';
import RSV from 'react-swipeable-views';

let NoOp = () => {};

let CurrentGalleryItem = (props) => {
	let context = useContext(Context);
	let item = context.state.elements[context.state.selectedIndex];
	let [ entered, setEntered ] = useState([context.state.selectedIndex]);
	useEffect(() => {
		if (entered.indexOf(context.state.selectedIndex) == -1)
			setEntered([ ...entered, context.state.selectedIndex ]);
	}, [ context.state.selectedIndex ]);
	
	let goNext = useCallback(() => context.dispatch({ type: 'set-selected-index', selectedIndex: context.state.selectedIndex + 1 }), [context]);
	let goBack = useCallback(() => context.dispatch({ type: 'set-selected-index', selectedIndex: context.state.selectedIndex - 1 }), [context]);
	let swipe = useCallback((index) => context.dispatch({ type: 'set-selected-index', selectedIndex: index }), [ context ]);
	let { classes } = props;
	// let imgSrc = item.src;

	let root = (
		<div className={clsx(classes.root, { [classes.withCaption]: props.showCaption && (item.title || item.summary) })}>
			<div className={clsx(classes.buttonWrapper, classes.previous)}>
				<IconButton className={classes.button} onClick={context.state.selectedIndex > 0 ? goBack : NoOp} disabled={context.state.selectedIndex == 0}>
					<Icon>keyboard_arrow_left</Icon>
				</IconButton>
			</div>
			<RSV className={classes.content} index={context.state.selectedIndex} onChangeIndex={swipe}>
				{context.state.elements.map((item, index) => (
					entered.indexOf(index) == -1 ? <div className={classes.contentOut}/> : (
						<div className={classes.contentItem}>
							<div className={classes.contentInner}>
								{item.embeddedObject ? (
									context.state.selectedIndex === index && (<div title={props.title} className={classes.embedObject} dangerouslySetInnerHTML={{ __html: item.embeddedObject.embedCode }}/>)
								) : (
									<img className={clsx(classes.image)} alt={props.title} src={item.src} />
								)}
							</div>
							{props.showCaption && <div className={classes.caption}>
								{props.title && <Typography variant='h4' className={classes.title}>{props.title}</Typography>}
								{props.summary && <Typography variant='body1' className={classes.summary}>{props.summary}</Typography>}
							</div>}
						</div>
					)
				))}
			</RSV>
			<div className={clsx(classes.buttonWrapper, classes.next)}>
				<IconButton className={classes.button} onClick={context.state.selectedIndex < (context.state.elements.length - 1) ? goNext : NoOp} disabled={context.state.selectedIndex == (context.state.elements.length - 1)}>
					<Icon>keyboard_arrow_right</Icon>
				</IconButton>
			</div>
		</div>
	);

	return root;
}

let Styles = makeStyles(theme => ({
	root: {
		outline: 0,
		display: 'flex',
		flexDirection: 'row',
		flex: '1 1 auto',
	},
	buttonWrapper: {
		flex: '0 0 auto',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},
	button: {
		flex: '0 0 auto',
	},
	previous: {},
	next: {},
	content: {
		flex: '1 1 auto',
		display: 'flex',
		flexDirection: 'column',
		'& > .react-swipeable-view-container': {
			flex: '1 1 auto',
			width: '100%',
			'& > div': {
				display: 'flex',
				flexDirection: 'column'
			}
		}
	},
	contentInner: {
		flex: '1 1 auto',
		position: 'relative',
	},
	caption: {
		flex: '0 0 auto'
	},
	loading: {},
	withCaption: {},
	skeleton: {
		borderRadius: theme.shape.borderRadius,
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0
	},
	image: {
		margin: 'auto',
		height: 'auto',
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		width: 'auto',
		maxWidth: '100%',
		maxHeight: '100%',
		objectFit: 'contain',
		borderRadius: theme.shape.borderRadius,
		'&$loading': {
			display: 'none !important'
		},
	},
	title: {
		color: theme.palette.primary.contrastText,
		marginTop: theme.spacing(1)
	},
	summary: {
		color: theme.palette.primary.contrastText,
		width: '70%',
		margin: 'auto',
		marginTop: theme.spacing(1)
	},
	embedObject: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		'& > *': {
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			width: '100%',
			height: '100%'
		}
	},
	contentOut: {},
	contentItem: {
		display: 'flex',
		flexDirection: 'column',
		flex: '1 1 auto'
	}
}), { name: 'SwGalleryCurrentItem' });

export default ThemeLoader(StyleLoader(CurrentGalleryItem, Styles));

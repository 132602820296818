import Root from './root/browser';
import BaseItem from './base-item/browser';
import Button from './button/browser';
import CallOut from './call-out/browser';
import Credits from './credits/browser';
import Download from './download/browser';
import Header from './header/browser';
import Image from './image/browser';
import List from './list/browser';
import Quote from './quote/browser';
import Text from './text/browser';
import Video from './video/browser';
import Tabs from './tabs/browser';
import Tab from './tab/browser';

export default {
	'article/base-item': BaseItem,
	'article/button': Button,
	'article/call-out': CallOut,
	'article/credits': Credits,
	'article/download': Download,
	'article/header': Header,
	'article/image': Image,
	'article/list': List,
	'article/quote': Quote,
	'article/text': Text,
	'article/video': Video,
	'article/root': Root,
	'article/tabs': Tabs,
	'article/tab': Tab
};


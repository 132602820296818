import { makeStyles } from '@material-ui/styles';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/SaveAlt';
import React from 'react';
import RawButtonBlock from '../../raw/button/block';

const FileSize = ({ size }) => {
	let suffix = 'B';
	if (size > 1000) {
		suffix = 'kB';
		size /= 1000;
	}
	if (size > 800) {
		suffix = 'MB';
		size /= 1000;
	}

	if (suffix == 'kB' || suffix == 'B') return `${Math.round(size)}${suffix}`;
	const v = size.toFixed(1);
	if (v.endsWith('.0')) return size.toFixed(0) + suffix;
	return v + suffix;
};

const DownloadBlock = ({ iconPosition, icon, variant, color, size, classes, file, title }, ref) => {
	const optionalProps = {};

	const pos = iconPosition === 'after' ? 'end' : 'start';

	const iconEl = (() => <Icon>{icon}</Icon>)();

	optionalProps[`${pos}Icon`] = iconEl;

	return (
		<Button
			variant={variant}
			color={color}
			size={size}
			className={classes.downloadButton}
			href={file}
			target="_blank"
			{...optionalProps}
		>
			{title}
		</Button>
	);
};

const Styles = makeStyles(theme => ({
	root: {},
	iconArea: {},
	downloadButton: {},
	downloadIcon: {},
	downloadType: {},
	infoArea: {},
	title: {},
	summary: {},
	size: {},
}));

export default ThemeLoader(StyleLoader(DownloadBlock, Styles));

import React, { Component, useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import { StyleLoader } from '@sightworks/theme';
import getChildren from '../../utils/children';
import Paper from '@material-ui/core/Paper';
import { useTheme } from '@material-ui/styles';
import * as Portal from '../../utils/portal';

const RawAppBarBlock = ({ appBar, classes, content }, ref) => (
	<AppBar {...appBar} elevation={0} classes={classes} ref={ref}>
		<Portal.Wrapper>
			<Paper elevation={2} square={appBar.square || false} style={{ zIndex: 1150 }}>
				{getChildren(content)}
			</Paper>
		</Portal.Wrapper>
	</AppBar>
);

export default StyleLoader(RawAppBarBlock);


import React, { useCallback, useRef } from 'react';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/core/styles';
import * as Registry from '../../registry';
import ReactDOMServer from 'react-dom/server';

const AddEventButtonBlock = (props, ref) => {
	const tgt = Registry.getBlock({ type: props.baseType });
	const addEvent = useRef(null);
	const cb = useCallback(node => {
		addEvent.current = node;
		if (node && typeof addeventatc == 'object') {
			addeventatc.refresh();
		}
	}, []);

	const showAddEvent = useCallback(() => {
		addEvent.current.querySelector('.addeventatc').dispatchEvent(new Event('click'));
	}, []);

	const { buttonContainer, ...classes } = props.classes;
	return <div className={buttonContainer}>
		{React.createElement(tgt, { ...props, classes, tgt, link: { internal: true, handler: showAddEvent } })}
		<div dangerouslySetInnerHTML={{
			__html: ReactDOMServer.renderToStaticMarkup(
				<AddEventButton {...props}/>
			)
		}} ref={cb}/>
	</div>
};

const AddEventButton = props => (
	<div className='addeventatc' title={props.buttonTitle}>
		{Object.entries(props.eventDetails).map(([ key, value ]) => (
			<span key={key} className={key}>{value}</span>
		))}
	</div>
);

export default ThemeLoader(
	StyleLoader(
		AddEventButtonBlock,
		makeStyles(
			theme => ({
				buttonContainer: {
					position: 'relative',
					alignSelf: 'flex-start',
					flexDirection: 'row',
					verticalAlign: 'middle',
					display: 'inline-flex',

					'& .addeventatc:not([data-loaded=true])': {
						display: 'none'
					},
					'& .addeventatc': {
						background: 'transparent',
						border: 'none',
						padding: 0,
						boxShadow: 'none !important',
						outline: 0,
						position: 'absolute',
						top: '100%',
						left: '4px',
					},
					'& .addeventatc_dropdown': {
						top: '100% !important',
						marginTop: '4px'
					},
					'& .addeventatc_icon': {
						display: 'none !important'
					}
				}
			}),
			{ name: "AddEventButton" }
		)
	)
);

import React, { useContext, useEffect, useState } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import { StyleLoader } from '@sightworks/theme';
import * as Registry from '../../registry';
import { makeStyles } from '@material-ui/core/styles';
import getChildren from '../../utils/children';
import { Context } from '../../utils/query';
import { useLinkAttributes } from '../../utils/linkContext';

let home = () => typeof document == 'undefined' ? '/' : document.querySelector('base').href;

let StripTabIndex = ({ tabIndex, ...rest }, ref) => <div {...rest} ref={ref}/>;
StripTabIndex = React.forwardRef(StripTabIndex);

let RawButtonBaseBlock = ({ content, link, classes }, ref) => {
	let context = useContext(Context);
	let lp = useLinkAttributes(link);
	let [ target, setTarget ] = useState(link ? (link.preserveQuery ? context.extend(link.href) : link.href) : null);
	
	useEffect(() => {
		let l = link || {};
		setTarget(l.href == '$home' ? home() : (l.preserveQuery ? context.extend(l.href) : l.href));
	}, [link]);

	return (
		<ButtonBase {...lp} href={target || void 0} component={link ? (lp.type=='submit' ? 'button' : "a") : StripTabIndex} classes={classes} ref={ref} focusRipple={link ? true : false} disableRipple={link ? false : true} role={link ? "button" : "region"} tabIndex={link ? 0 : -1} target={link && link.openInNewWindow ? '_blank' : void 0}>
			{getChildren(content)}
		</ButtonBase>
	);
}

export default StyleLoader(RawButtonBaseBlock, makeStyles({
	root: {
		'div&': {
			cursor: 'default'
		}
	}
}, { name: 'SwRawButtonBase' }));


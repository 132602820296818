import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';

const ActionContext = createContext({});

const Provider = ({ children }) => {
	const [target, setTarget] = useState(null);
	return <ActionContext.Provider value={{ target, setTarget }}>{children}</ActionContext.Provider>;
};

export { ActionContext as Context };
export default Provider;

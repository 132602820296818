export default theme => ({
	isAgenda: {},
	toolbarTitle: {},
	toolbar: {
		'& $toolbarTitle': {
			textAlign: 'left',
			'$isAgenda &': {
				textAlign: 'center'
			}
		},
		'$isAgenda &': {
			flexDirection: 'column'
		}
	},
	toolbarButtonGroup: {
		'$isAgenda &': {
			display: 'flex',
			flex: 1,
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
			width: '100%',
			'& > button:first-child + button': {
				flex: 1
			}
		}
	}
});

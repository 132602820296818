import React from 'react';
import { ThemeLoader } from '@sightworks/theme';
import * as Registry from '../../registry';

const ButtonBlock = (props, ref) => {
	const tgt = Registry.getBlock({ type: props.baseType });
	return React.createElement(tgt, { ...props, tgt });
};

export default ThemeLoader(ButtonBlock);

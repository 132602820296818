import { makeStyles } from '@material-ui/styles';

const margins = new Map();
const root = {
	styles: detail => {
		let v = [detail.top, detail.side, detail.bottom].join('-');
		if (!margins.has(v)) {
			margins.set(v, makeStyles(theme => ({
				root: {
					marginTop: theme.spacing(detail.top),
					marginLeft: theme.spacing(detail.side),
					marginRight: theme.spacing(detail.side),
					marginBottom: theme.spacing(detail.bottom)
				}
			}), { name: `margins-${v}`, index: 10000 }));
		}
		return margins.get(v);
	}
}

export { root };

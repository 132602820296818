import React from 'react';
import * as Registry from '../registry';

export default function getChildren(children, wrap = null, call = false) {
	return children
		.map((child, index) => {
			if (child) {
				if (wrap) {
					return wrap(React.createElement(Registry.getBlock(child), child), child, index);
				}
				if (call) {
					let e = Registry.getBlock(child, true);
					while (e.render) e = e.render;
					return e(child);
				}
				return React.createElement(Registry.getBlock(child), {
					key: child.id,
					...child,
				});
			}
			return null;
		})
		.filter(v => !!v);
}

import React, { Fragment, useState, useContext, useEffect, useRef, useCallback } from 'react';
import Modal from '@material-ui/core/Modal';
import ReactDOM from 'react-dom';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import { makeStyles, useTheme } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Backdrop from '@material-ui/core/Backdrop';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import getChildren from '../../utils/children';
import * as Portal from '../../utils/portal';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

const NavDrawerBlock = ({
	content, classes, accessibilityTitle, id, sections, button, hamburger, hamburgerBp, drawerBackground
}, ref) => {
	let [ current, setCurrent ] = useState('closed');
	let portalWrapper = useContext(Portal.Provider);
	let [ element, setElement ] = useState(null);
	let theme = useTheme();
	hamburger = hamburger || false;

	useEffect(() => {
		if (portalWrapper) {
			let e = document.createElement('div');
			portalWrapper.appendChild(e);
			setElement(e);
		} else if (element) {
			portalWrapper.removeChild(element);
			setElement(null);
		}
		return () => {
			if (element) {
				portalWrapper.removeChild(element);
				setElement(null);
			}
		};
	}, [ portalWrapper ]);

	let root = useRef({ parentNode: { offsetWidth: 0,  offsetHeight: 0 }, focus() {} });
	let [gotRoot] = useState(() => node => {
		root.current = node;
		if (current == 'open' && node) {
			console.log(`focus on`, node);
			node.focus();
		}
	});
	let buttonRef = useRef(null);
	let gotButton = useState(() => node => {
		buttonRef.current = node;
	});

	useEffect(() => {
		if (current == 'closing' || current == 'opening1') {
			let v = setTimeout(() => {
				setCurrent(current => current == 'opening1' ? 'open' : 'closed');
				if (current == 'closing' && buttonRef.current) buttonRef.current.focus();
			}, 300);
			return () => clearTimeout(v);
		}
		if (current == 'opening') {
			let v = setTimeout(() => {
				setCurrent(current => current == 'opening' ? 'opening1' : 'closed');
			}, 0);
			return () => clearTimeout(v);
		}
		if (root.current && current == 'open') {
			console.log(`focus on`, root.current);
			root.current.focus();
		}
	}, [current, buttonRef.current, root.current]);
	let children = getChildren(content);

	// let { boxRoot, gridRoot, expansionPanelRoot, ...rest } = classes;

	if (hamburger) {
		return (
			<ExpansionPanel square classes={{
				root: clsx(classes.root, classes.expansionPanelRoot),
				rounded: classes.expansionPanelRootRounded,
				expanded: classes.expansionPanelRootExpanded,
				disabled: classes.expansionPanelRootDisabled
			}}>
				<ExpansionPanelSummary
					expandIcon={<Icon className={clsx(classes.expansionPanelExpandoIcon)}>expand_more</Icon>}
					aria-controls={`panel-${id}-content`}
					id={`panel-${id}-header`}
					classes={{
						root: classes.expansionPanelSummary,
						expanded: classes.expansionPanelSummaryExpanded,
						focused: classes.expansionPanelSummaryFocused,
						disabled: classes.expansionPanelSummaryDisabled,
						content: classes.expansionPanelSummaryContent,
						expandIcon: classes.expansionPanelSummaryExpandIcon
					}}
				>
					{button.icon && button.iconPosition == 'before' && <Icon className={clsx(classes.expansionPanelSummaryIcon)}>{button.icon}</Icon>}
					<Typography variant={button.textVariant} className={clsx(classes.expansionPanelSummaryText)}>{button.title}</Typography>
					{button.icon && button.iconPosition == 'after' && <Icon className={clsx(classes.expansionPanelSummaryIcon)}>{button.icon}</Icon>}
				</ExpansionPanelSummary>
				<ExpansionPanelDetails classes={{ root: clsx(classes.expansionPanelDetails) }}>
					{sections.length > 0 && (
						<Box className={clsx(classes.box, classes.expansionPanelBox)}>
							<Grid container className={clsx(classes.grid, classes.expansionPanelGrid)} alignContent="stretch" alignItems="stretch" direction="row" justify="flex-start" spacing={3}>
								{getChildren(sections.map(s => ({ ...s, breakpoint: hamburgerBp })))}
							</Grid>
						</Box>
					)}
					{children}
				</ExpansionPanelDetails>
			</ExpansionPanel>
		);
	}

	if (!element) {
		return (
			<Button role="tab" size={button.size} variant={button.variant} id={`tabbutton-${id}`} onClick={() => setCurrent('closing')} fullWidth={false} color={button.color} className={clsx(classes.root, classes.tabButtonRoot)} aria-controls={`tab-${id}`}>
				{button.icon && button.iconPosition == 'before' && <Icon className={classes.tabButtonIcon}>{button.icon}</Icon>}
				<Typography variant={button.textVariant} className={classes.tabButtonText}>{button.title}</Typography>
				{button.icon && button.iconPosition == 'after' && <Icon className={classes.tabButtonIcon}>{button.icon}</Icon>}
			</Button>
		);
	}

	let d = (
		<>
			<Backdrop invisible open={current != 'closed'} className={classes.backdrop} />
			<ClickAwayListener onClickAway={() => setCurrent(current == 'open' ? 'closing' : current)} {...(open ? {} : {mouseEvent: false, touchEvent: false})}>
				<Paper elevation={1} square={true}
					className={clsx(classes.paper, {
						[classes.paperOpened]: current == 'open' || current == 'opening1',
						[classes.paperClosed]: current == 'closed'
					})}
					tabIndex={current == 'open' ? 0 : -1} id={`tab-${id}`}
					aria-expanded={current == 'open' ? 'true' : 'false'} role='tabpanel'
					aria-describedby={`tabbutton-${id}`}
				>
					<div className={classes.topPadding}/>
					<Typography component="div" ref={gotRoot} variant="srOnly" tabIndex={0}>{accessibilityTitle}</Typography>
					{sections.length > 0 && (
						<Box className={clsx(classes.box, classes.drawerBox, {
							[classes.drawerBoxWithImage]: !!(drawerBackground || {image: ''}).image
						})} style={{
							backgroundImage: (drawerBackground || { image: '' }).image ? `url(${drawerBackground.image})` : 'none',
							backgroundColor: (drawerBackground || { color: '' }).color ? drawerBackground.color : void 0,
						}}>
							<Grid container className={clsx(classes.grid, classes.drawerGrid)} alignContent="stretch" alignItems="stretch" direction="row" justify="flex-start" spacing={3}>
								{getChildren(sections)}
							</Grid>
						</Box>
					)}
					{children}
					<Typography variant="srOnly" component="button" type="button" style={{ padding: 0, margin: 0, border: 0 }} onClick={() => setCurrent('closing')}>Close</Typography>
				</Paper>
			</ClickAwayListener>
		</>
	);

	let p = ReactDOM.createPortal(d, element);

	return (
		<Fragment>
			<Button role="tab" size={button.size} variant={button.variant} id={`tabbutton-${id}`}
				onClick={() => setCurrent(current == 'open' ? 'closing' : 'opening')} fullWidth={false} color={button.color}
				className={
					clsx(classes.root, classes.tabButtonRoot, {
						[classes.tabButtonOpen]: ['open', 'opening', 'opening1'].indexOf(current) != -1
					})
				}
				aria-controls={`tab-${id}`} ref={gotButton}
			>
				{button.icon && button.iconPosition == 'before' && <Icon className={classes.tabButtonIcon}>{button.icon}</Icon>}
				<Typography variant={button.textVariant} className={classes.tabButtonText}>{button.title}</Typography>
				{button.icon && button.iconPosition == 'after' && <Icon className={classes.tabButtonIcon}>{button.icon}</Icon>}
			</Button>
			{
				p
			}
		</Fragment>
	);
}

const DrawerCustomStyle = makeStyles(theme => ({
	root: {},

	box: {
		padding: theme.spacing(2),
		marginLeft: 'auto',
		marginRight: 'auto',
		backgroundColor: 'rgb(246, 246, 246)'
	},
	grid: {
		margin: 0,
		marginLeft: theme.spacing(4),
		marginRight: theme.spacing(4),
		padding: theme.spacing(2),
		width: `calc(100% - ${theme.spacing(8)}px)`
	},

	expansionPanelRoot: {},
	expansionPanelRootRounded: {},
	expansionPanelRootExpanded: {},
	expansionPanelRootDisabled: {},

	expansionPanelSummary: {},
	expansionPanelSummaryIcon: {},
	expansionPanelSummaryText: {},
	expansionPanelSummaryExpanded: {},
	expansionPanelSummaryFocused: {},
	expansionPanelSummaryDisabled: {},
	expansionPanelSummaryContent: {},
	expansionPanelSummaryExpandIcon: {},

	expansionPanelExpandoIcon: {},
	expansionPanelDetails: {
		padding: 0
	},
	expansionPanelBox: {},
	expansionPanelGrid: {
		width: '100%',
		marginLeft: 0,
		marginRight: 0,
		padding: 0
	},

	tabButtonRoot: {},
	tabButtonIcon: {},
	tabButtonText: {},
	tabButtonOpen: {},

	backdrop: {},
	paper: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		transition: theme.transitions.create(),
		visibility: 'visible',
		transform: 'translateY(-100%)'
	},
	paperClosed: {
		visibility: 'hidden'
	},
	paperOpened: {
		transform: 'translateY(0)'
	},

	topPadding: {
		height: theme.spacing(1)
	},
	drawerBox: {},
	drawerBoxWithImage: {
		paddingRight: 250 + theme.spacing(2)
	},
	drawerGrid: {}
}), { name: 'NavDrawer' });

const ThemedNavDrawerBlock = ThemeLoader(StyleLoader(NavDrawerBlock, DrawerCustomStyle));

export default ThemedNavDrawerBlock;

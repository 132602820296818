import React, { useContext, useEffect, useState } from 'react';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
// import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import clsx from 'clsx';
import { Context } from '../../containers/filter/context';

const FilterButtonBlock = (props, ref) => {
	const context = useContext(Context);

	let [ currentState, setCurrentState ] = useState(context.open ? 'open' : 'closed');
	useEffect(() => {
		let targetStates = context.open ? [ 'opening', 'open' ] : [ 'closing', 'closed' ];
		let targetState = targetStates[1];
		if (targetStates.indexOf(currentState) == -1) {
			setCurrentState(targetState == 'closed' ? 'closing' : 'opening');
			setTimeout(() => {
				setCurrentState(state => {
					if (state == (targetState == 'closed' ? 'closing' : 'opening'))
						return targetState;
					return state;
				});
			}, 300);
		}
	}, [ currentState, context.open ]);
	
	useEffect(() => {
		let q = location.search
			.substring(1)
			.split('&')
			.filter(v => !!v)
			.map(v => v.split('='))
			.map(([key, ...value]) => [key, value.join('=')].map(decodeURIComponent))
			.map(([key, value]) => ({ key, value }));
		const f = q.find(({ key }) => key == `${context.key}_open`);
		if (context.open) {
			if (!f) q.push({ key: `${context.key}_open`, value: '1' });
			else f.value = '1';
		} else if (f) {
			q.splice(q.indexOf(f), 1);
		}
		q = q.map(({ key, value }) => [key, value].map(encodeURIComponent).join('=')).join('&');
		history.replaceState(
			{
				...history.state,
				[`${context.key}_open`]: context.open,
			},
			document.title,
			location.pathname + (q ? `?${q}` : '')
		);
	}, [context.key, context.open]);

	let isDefault = true;
	if (context.defaultSort != context.currentSort) isDefault = false;
	if (context.selectedTags && context.selectedTags.length) isDefault = false;
	for (let fieldName in context.fields) {
		if (context.fields[fieldName].selected.length) isDefault = false;
	}

	return (
		<div className={clsx(props.classes.outer, props.classes[currentState], {
			[props.classes.withText]: props.showTitle && context && !context.filterInDrawer
		})} style={{ '--column-size': context.columnSize }}>
			<Badge
				invisible={isDefault}
				variant="dot"
				color="primary"
				overlap="circle"
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				className={props.classes.badge}
			>
				<Button
					variant={context.open ? 'contained' : 'outlined'}
					className={clsx(props.classes.root, {
						[props.classes.active]: context.open,
					})}
					onClick={context.toggle}
					color={props.color}
					size={props.size}
					{...(props.accessibleTitle ? { 'aria-label': props.accessibleTitle } : {})}
				>
					<Icon className={props.classes.icon}>{props.icon}</Icon>
				</Button>
			</Badge>
			{props.showTitle && context && !context.filterInDrawer && (<span className={clsx(props.classes.title)}>{props.title}</span>)}
		</div>
	);
};

export default ThemeLoader(
	StyleLoader(
		FilterButtonBlock,
		makeStyles(
			theme => ({
				outer: {},
				withText: {
					width: 40,
					transition: 'width 0.3s ease',
					overflow: 'hidden',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					'&$opening, &$open': {
						width: 'var(--column-size)'
					},
					'&$closing, &$closed': {
						width: 40
					}
				},
				root: {
					padding: theme.spacing(1) - 1,
					minWidth: 40,
					width: 40,
					borderRadius: '100%',
				},
				icon: {},
				active: {
					padding: theme.spacing(1),
				},
				badge: {
					'& .MuiBadge-badge': {
						height: theme.spacing(1.25),
						width: theme.spacing(1.25),
						background: `transparent radial-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main} 3px, white, white 4px, ${theme.palette.primary.main} 4px)`,
					},
				},
				title: {
					...theme.typography.h6,
					marginLeft: theme.spacing(1)
				},
				open: {},
				opening: {},
				closing: {},
				closed: {}
			}),
			{ name: 'SWFilterButton' }
		)
	)
);

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

const DialogTitle = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(4),
		position: 'relative',
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(2),
		top: '50%',
		transform: 'translateY(-50%)',
		color: theme.palette.grey[500],
	},
}))(({ classes, onClose, className, children, ...rest }) => (
	<MuiDialogTitle disableTypography className={clsx(classes.root, className)} {...rest}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
			<IconButton aria-hidden className={classes.closeButton} onClick={onClose}>
				<Icon>close</Icon>
			</IconButton>
		) : null}
	</MuiDialogTitle>
));

export default DialogTitle;

export default theme => ({
	root: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.text.primary,
		'& [class*=MuiGrid-item-]': {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'stretch',
			alignItems: 'stretch',
			[theme.breakpoints.up('md')]: {
				minHeight: 128,
				padding: theme.spacing(1),
				paddingLeft: theme.spacing(0.5),
				paddingRight: theme.spacing(0.5),
				'&:first-child': {
					paddingLeft: theme.spacing(1),
				},
				'&:last-child': {
					paddingRight: theme.spacing(1),
				},
			},
			[theme.breakpoints.only('sm')]: {
				padding: theme.spacing(0.5),
				'&:nth-child(-n+2)': {
					paddingTop: theme.spacing(1),
				},
				'&:nth-child(2n+1)': {
					paddingLeft: theme.spacing(1),
				},
				'&:nth-child(2n)': {
					paddingRight: theme.spacing(1),
				},
				'&:nth-last-child(-n+2)': {
					paddingBottom: theme.spacing(1),
				},
			},
			[theme.breakpoints.only('xs')]: {
				padding: theme.spacing(2),
				paddingTop: theme.spacing(1),
				paddingBottom: theme.spacing(1),
				'&:first-child': {
					paddingTop: theme.spacing(2),
				},
				'&:last-child': {
					paddingBottom: theme.spacing(2),
				},
			},
			'& > *': {
				flex: 1,
				alignItems: 'center',
				justifyContent: 'flex-start',
				display: 'flex',
				padding: theme.spacing(1),
				[theme.breakpoints.up('md')]: {
					paddingLeft: theme.spacing(2.5),
					paddingRight: theme.spacing(2.5),
				},
			},
			'& > [class*=MuiButtonBase-root-]': {
				// padding: theme.spacing(1),
				borderRadius: theme.shape.borderRadius,
				background: 'rgba(255, 255, 255, 0)',
				transition: theme.transitions.create(),
				'&:hover': {
					background: 'rgba(255, 255, 255, .2)',
				},
				'& > [class*=MuiTouchRipple-root-]': {
					display: 'none',
				},
				[theme.breakpoints.only('xs')]: {
					'& [class*=MuiButton-label-]': {
						justifyContent: 'center',
						textAlign: 'center',
					},
				},
			},
			'& > [class*=MuiTypography-root-]': {
				[theme.breakpoints.only('xs')]: {
					justifyContent: 'center',
					textAlign: 'center',
				},
			},
		},
	},
});

import Button from './button/browser';
import ButtonBase from './button-base/browser';
import Container from './container/browser';
import Embed from './embed/browser';
import Freeform from './freeform/browser';
import GridContainer from './grid-container/browser';
import GridItem from './grid-item/browser';
import Icon from './icon/browser';
import IconButton from './icon-button/browser';
import Image from './image/browser';
import Plaintext from './plaintext/browser';
import Box from './box/browser';
import Paper from './paper/browser';
import AppBar from './app-bar/browser';
import Chip from './chip/browser';
import Avatar from './avatar/browser';

export default {
	'raw/button': Button,
	'raw/button-base': ButtonBase,
	'raw/container': Container,
	'raw/embed': Embed,
	'raw/freeform': Freeform,
	'raw/grid-container': GridContainer,
	'raw/grid-item': GridItem,
	'raw/icon': Icon,
	'raw/icon-button': IconButton,
	'raw/image': Image,
	'raw/plaintext': Plaintext,
	'raw/box': Box,
	'raw/paper': Paper,
	'raw/app-bar': AppBar,
	'raw/chip': Chip,
	'raw/avatar': Avatar,
};

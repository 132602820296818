import React, { Component, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { StyleLoader } from '@sightworks/theme';
import Styled from '../../utils/styled';
import getChildren from '../../utils/children';

const RawGridItemBlock = ({ classes, grid, content }, ref) => (
	<Grid item classes={classes} {...grid} ref={ref}>
		{getChildren(content)}
	</Grid>
);

export default StyleLoader(RawGridItemBlock);



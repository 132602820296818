import React, { Component, useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { StyleLoader } from '@sightworks/theme';
import getChildren from '../../utils/children';

const PaperBlock = ({ paper, classes, content }, ref) => (
	<Paper {...paper} classes={classes} ref={ref}>
		{getChildren(content)}
	</Paper>
);
export default StyleLoader(PaperBlock);



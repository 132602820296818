import React, { useContext, useState, useCallback, useEffect, useRef } from 'react';
import { Context } from '../gallery-container/context';
import clsx from 'clsx';
import imageIsCached from '../../utils/imageIsCached';
import Skeleton from '@material-ui/lab/Skeleton';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Animation from '@sightworks/transition';

const GalleryItems = (props, ref) => {
	let context = useContext(Context);
	let { classes, showCaption, useSmallImages, scrolling, height } = props;

	if (scrolling) {
		return (
			<GalleryWrapper {...props}>
				{context.state.elements.map((innerProps, index) => (
					<GalleryItem {...props} {...innerProps} index={index} key={index} />
				))}
			</GalleryWrapper>
		);
	}
	
	return context.state.elements.map((innerProps, index) => (
		<GalleryItem {...props} {...innerProps} index={index} key={index} />
	));
};

let GalleryWrapper = ({ children, classes, height }) => {
	let context = useContext(Context);
	let [ scrollLeft, setScrollLeft ] = useState(0);
	let node = useRef(null);
	let target = useRef({ start: 0, end: 0 });
	let animation = useRef(new Animation(
		300,
		'ease',
		percent => node.current.scrollLeft = target.current.start + (percent * (target.current.end - target.current.start)),
		() => {
			node.current.scrollLeft = target.current.end;
			setScrollLeft(node.current.scrollLeft);
		}
	));
	let doScroll = useCallback(() => setScrollLeft(node.current.scrollLeft), []);
	let shiftItem = useCallback((dir = 1) => {
		let lps = [].filter.call(node.current.childNodes, node => node.nodeType == 1).map(node => node.offsetLeft);
		let firstFullItem = lps.find(lp => lp >= node.current.scrollLeft) || 0;
		let itemIndex = lps.indexOf(firstFullItem);
		if (itemIndex == -1) itemIndex = 0;
		let nextItem = itemIndex + dir;
		let nextPos = nextItem < 0 ? 0 : (nextItem >= lps.length ? lps[lps.length - 1] : lps[nextItem]);
		target.current = { start: node.current.scrollLeft, end: nextPos };
		if (animation.current.ended) animation.current.start();
	}, []);
	let shiftLeft = useCallback(() => shiftItem(-1), [shiftItem]);
	let shiftRight = useCallback(() => shiftItem(1), [shiftItem]);
	useEffect(() => {
		if (node.current) {
			let nodes = [].filter.call(node.current.childNodes, node => node.nodeType == 1);
			let item = nodes[context.state.selectedIndex];
			if (!item) {
				debugger;
				return;
			}
			if (item.offsetLeft < node.current.scrollLeft) {
				target.current = {
					start: node.current.scrollLeft,
					end: item.offsetLeft
				};
				if (animation.current.ended) animation.current.start();
			} 
			if ((item.offsetLeft + item.offsetWidth) > (node.current.scrollLeft + node.current.offsetWidth)) {
				target.current = {
					start: node.current.scrollLeft,
					end: (item.offsetLeft + item.offsetWidth) - node.current.offsetWidth
				}
				if (animation.current.ended) animation.current.start();
			}
		}
	}, [ node.current, context.state.selectedIndex ]);
	let [ isOverflowed, setOverflowed ] = useState(false);
	useEffect(() => {
		let resize = () => {
			if (node.current) {
				setOverflowed(node.current.offsetWidth < node.current.scrollWidth);
			}
		}
		resize();
		window.addEventListener('resize', resize);
		return () => window.removeEventListener('resize', resize);
	}, [ node.current ]);
	
	return (
		<div className={clsx(classes.scroller, { [classes.overflow]: isOverflowed })} style={{ height: `calc(${height} + var(--selected-bar-size))`, '--item-width': height }}>
			<div className={classes.buttonWrapper}>
				<IconButton onClick={shiftLeft} className={classes.button}>
					<Icon>keyboard_arrow_left</Icon>
				</IconButton>
			</div>
			<div className={classes.scrollerContent} ref={node} onScroll={doScroll} style={{ height: `calc(${height} + 20px + var(--selected-bar-size))` }}>
				{children}
			</div>
			<div className={classes.buttonWrapper}>
				<IconButton onClick={shiftRight} className={classes.button}>
					<Icon>keyboard_arrow_right</Icon>
				</IconButton>
			</div>
		</div>
	);
}

let GalleryItem = (props) => {
	let context = useContext(Context);
	let [ loading, setLoading ] = useState(true);
	useEffect(() => {
		setLoading(true);
	}, [props.src]);
	let didLoad = useCallback(() => setLoading(false), [props.src]);
	let { classes } = props;
	let imgSrc = props.useSmallImages ? `/t/square/matte=none/width=500/scale/f${props.src}/scaled.jpg` : props.src;
	let isCurrent = context.state.selectedIndex == props.index;
	let setCurrent = useCallback(() => context.dispatch({ type: 'set-selected-index', selectedIndex: props.index }), [props.index]);

	let root = <div className={clsx(classes.root, { [classes.loading]: loading, [classes.withCaption]: props.showCaption && (props.title || props.summary), [classes.selected]: isCurrent })} onClick={setCurrent}>
		{loading && <Skeleton variant="rect" className={classes.skeleton} />}
		<img className={clsx(classes.image, { [classes.loading]: loading })} alt={props.title} src={imgSrc} onLoad={didLoad}/>
		{props.embeddedObject && props.embeddedObject.icon && <Icon className={classes.embedIcon}>{props.embeddedObject.icon}</Icon>}
	</div>;

	if (props.wrap) {
		let Wrap = props.wrap;
		return <Wrap onResize={props.onResize} getRawNode={() => root}/>;
	}

	return root;
}

let Styles = makeStyles(theme => ({
	root: {
		'--selected-bar-size': `${theme.spacing(2)}px`,
		outline: 0,
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(0, 3)
		},
		transition: theme.transitions.create('opacity'),
		opacity: 0.5,
		position: 'relative',
		'&$selected': {
			position: 'relative',
			opacity: 1,
			'&:after': {
				position: 'absolute',
				top: `calc(100% + ${theme.spacing(1)}px)`,
				left: 0,
				right: 0,
				height: theme.spacing(1),
				background: theme.palette.secondary.main,
				content: '""'
			}
		}
	},
	embedIcon: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		fontSize: '2rem',
		color: '#fff',
		textShadow: 'rgba(0, 0, 0, .5) 0px 2px 2px',
		transform: 'translate(-50%, -50%)'
	},
	loading: {},
	withCaption: {},
	selected: {},
	skeleton: {
		borderRadius: theme.shape.borderRadius,
		paddingTop: '56.25%',
		width: '80%'
	},
	image: {
		margin: 'auto',
		height: 'auto',
		maxWidth: '100%',
		maxHeight: '80vh',
		borderRadius: theme.shape.borderRadius,
		'&$loading': {
			display: 'none !important'
		},
	},
	title: {
		color: theme.palette.primary.contrastText,
		marginTop: theme.spacing(1)
	},
	summary: {
		color: theme.palette.primary.contrastText,
		width: '70%',
		margin: 'auto',
		marginTop: theme.spacing(1)
	},
	scroller: {
		display: 'flex',
		flexDirection: 'row',
		overflow: 'hidden',
		'--selected-bar-size': `${theme.spacing(2)}px`,
		[theme.breakpoints.down('sm')]: {
			'& > $buttonWrapper': {
				display: 'none'
			}
		},
		'&:not($overflow) > $buttonWrapper': {
			opacity: 0,
			visibility: 'hidden'
		}
	},
	overflow: {
	},
	button: {},
	buttonWrapper: {
		flex: '0 0 auto',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		'& > $button': {
			margin: 'auto'
		}
	},
	scrollerContent: {
		overflowX: 'scroll',
		overflowY: 'hidden',
		paddingBottom: 20 + theme.spacing(2), // See the '+ 20px' in the style above. We don't actually care about this size: that's why overflowY = 'hidden'
		WebkitOverflowScrolling: 'touch',
		flex: '1 1 auto',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		position: 'relative', // Make 'offsetLeft' be relative to this box
		'& $root': {
			padding: 0,
			flex: '0 0 var(--item-width)',
			padding: 0,
			margin: theme.spacing(0, 2, 0, 0),
			'&:first-child': {
				marginLeft: 'auto'
			},
			'&:last-child': {
				marginRight: 'auto'
			}
		},
		'& $image': {
			maxWidth: '100%',
			display: 'block',
			height: 'initial',
			width: 'initial',
			margin: 0
		}
	}
}), { name: 'SwGalleryItems' });

export default ThemeLoader(StyleLoader(GalleryItems, Styles));
export { GalleryItems };

GalleryItem = ThemeLoader(StyleLoader(GalleryItem, Styles));


import { makeStyles } from '@material-ui/styles';
import { StyleLoader } from '@sightworks/theme';
import clsx from 'clsx';
import React from 'react';

const VideoBlock = ({ classes, video, title, credits }, ref) => {
	return (
		<div className={classes.root}>
			<div dangerouslySetInnerHTML={{ __html: video }} className={classes.video} />
			{(title || credits) && (
				<figcaption>
					{title && <p className={classes.title}>{title}</p>}
					{credits && <p className={classes.credits}>{credits}</p>}
				</figcaption>
			)}
		</div>
	);
};

const Styles = makeStyles(theme => ({
	root: {},
	video: {
		display: 'flex',
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		maxWidth: '1080px',
		position: 'relative',
		height: 'auto',
		margin: '0px auto',
		'&:before': {
			width: '100%',
			paddingTop: '56.25%',
			display: 'block',
			content: '""',
		},
		'& iframe, & video': {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
		},
		'& iframe': {
			borderRadius: theme.shape.borderRadius,
		},
	},
	title: {},
	credits: {},
}));

export default StyleLoader(VideoBlock, Styles);

import Box from '@material-ui/core/Box';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import React from 'react';
import getChildren from '../../utils/children';

const DetailsBlock = (props, ref) => (
	<Box ref={ref} className={props.classes.root}>
		{getChildren(props.content)}
	</Box>
);
export default ThemeLoader(StyleLoader(DetailsBlock));

import React, { useCallback } from 'react';
import useBreakpoint from '@sightworks/block/lib/utils/useBreakpoint';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';

const DropdownField = ({ dispatch, id, error, title, classes, value, content, status }) => {
	const callback = useCallback(event => {
		dispatch({ action: 'setField', data: { [id]: event.target.value } });
	}, [ dispatch, id ]);
	const breakpoint = useBreakpoint();
	const x = {};
	if (error) {
		x.error = true;
		x.helperText = "Please fill out this field.";
	}
	const Item = breakpoint == 'xs' ? 'option' : MenuItem;

	return (
		<TextField select SelectProps={{ native: breakpoint == 'xs' }} variant='filled' label={title} className={clsx(classes.field, classes.dropdown)} value={value} onChange={callback} disabled={status != 'READY'} {...x}>
			{content.map(value => <Item key={value} value={value}>{value}</Item>)}
		</TextField>
	);
}

export default DropdownField;
export const Style = {
	dropdown: {
		flex: 1
	}
};

import React from 'react';
import { ThemeLoader } from '@sightworks/theme';
import RawButtonBaseBlock from '../../raw/button-base/block';

const ButtonBaseBlock = (props, ref) => <RawButtonBaseBlock {...props} ref={ref}/>;
const ThemedButtonBaseBlock = ThemeLoader(ButtonBaseBlock);
export default ThemedButtonBaseBlock;




import { makeStyles } from '@material-ui/styles';
import { StyleLoader } from '@sightworks/theme';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';

const ImageBlock = (
	{ classes, image, title, credits, altText, withBorder, borderColor, noUpscale, alignment },
	ref
) => {
	return (
		<figure className={clsx(classes.root, classes[alignment])}>
			<img
				src={image}
				className={clsx(classes.image, {
					[classes.borderSmall]: withBorder == 'Small',
					[classes.borderMedium]: withBorder == 'Medium',
					[classes.borderLarge]: withBorder == 'Large',
					[classes.noUpscale]: noUpscale,
				})}
				style={{ borderColor }}
				alt={altText}
			/>
			{(title || credits) && (
				<figcaption>
					{title && (
						<Typography variant="h4" className={clsx(classes.title)}>
							{title}
						</Typography>
					)}
					{credits && (
						<Typography variant="body1" className={classes.credits}>
							{credits}
						</Typography>
					)}
				</figcaption>
			)}
		</figure>
	);
};

const Styles = makeStyles(
	theme => ({
		root: {
			margin: 0,
		},
		borderSmall: {},
		borderMedium: {},
		borderLarge: {},
		noUpscale: {},
		title: {},
		credits: {},
		image: {
			[theme.breakpoints.down('xs')]: {
				height: 'auto',
				width: '100%',
			},
			height: '100%',
			width: 'auto',
		},
		Center: {
			textAlign: 'center',
		},
		Left: {
			textAlign: 'left',
		},
		Right: {
			textAlign: 'center',
		},
	}),
	{ name: 'SWArticle-Image' }
);

export default StyleLoader(ImageBlock, Styles);

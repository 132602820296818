import React, { useContext, createContext, useMemo } from 'react';
import { update } from '../registry';

const NavigatorContext = createContext({
	next: null,
	navigator: update,
	navigate: href => update(href)
});
const NavigatorProvider = ({ navigate, children }) => {
	let currentContext = useContext(NavigatorContext);
	let nextContext = useMemo(() => {
		let r = {
			next: currentContext,
			navigator: navigate
		};
		r.navigate = async href => {
			let self = r;
			while (self) {
				if (await self.navigator(href)) return;
				self = self.next;
			}
		}
		return r;
	}, [ currentContext, navigate ]);
	return <NavigatorContext.Provider value={nextContext}>{children}</NavigatorContext.Provider>
}

export default NavigatorProvider;
export { NavigatorContext as Context };


import React, { Component, useState, useEffect } from 'react';
import { useTheme } from '@material-ui/styles';

const PortalProvider = React.createContext(null);
const PortalWrapper = props => {
	const [portal, setPortal] = useState(null);
	const theme = useTheme();

	return (
		<>
			<PortalProvider.Provider value={portal}>{props.children}</PortalProvider.Provider>
			<div
				ref={setPortal}
				style={{ position: 'absolute', top: '100%', marginTop: theme.spacing(-1), left: 0, right: 0, zIndex: 1140 }}
			/>
		</>
	);
};

export { PortalProvider as Provider, PortalWrapper as Wrapper };

import { makeStyles } from '@material-ui/styles';

const styles = makeStyles(theme => ({
	root: props => ({
		padding: theme.spacing(3),
		width: 360,
		height: 360,
		marginLeft: 'auto',
		marginRight: 'auto',
		'& > img': {
			width: 220,
			height: 220,
			transition: '.3s ease'
		},
		'&:hover > img': {
			width: 125,
			height: 125
		},
		[`& > .MuiTypography-${props.content[props.content.length - 1].text.variant}`]: {
			height: 0,
			transition: '.3s ease',
			opacity: 0,
			overflow: 'hidden'
		},
		[`&:hover > .MuiTypography-${props.content[props.content.length - 1].text.variant}`]: {
			height: (2 * theme.typography[props.content[props.content.length - 1].text.variant].lineHeight) + 'em',
			opacity: 1
		}
	})
}), { name: 'shrinking-icon', index: 10000 });

const root = {
	styles: detail => styles
};

export { root };

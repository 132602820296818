import React from 'react';
import PropTypes from 'prop-types';
import { StyleLoader } from '@sightworks/theme';
import Chip from '@material-ui/core/Chip';

const RawChipBlock = ({ icon, label, classes }, ref) => (
	<Chip icon={icon} label={label} classes={classes} ref={ref} />
);

const RefChipBlock = React.forwardRef(RawChipBlock);
RefChipBlock.propTypes = {
	icon: PropTypes.element.isRequired,
	label: PropTypes.string.isRequired,
	classes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any).isRequired).isRequired,
};

export default StyleLoader(RefChipBlock);

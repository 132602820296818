import React, { useRef, useEffect, useState } from 'react';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Zoom from '@material-ui/core/Zoom';
import Grow from '@material-ui/core/Grow';
import Collapse from '@material-ui/core/Collapse';
import Slide from '@material-ui/core/Slide';
import getChildren from '../../utils/children';

const TransitionBlock = ({ content, duration, transitionType, direction }, ref) => {
	const [toggle, setToggle] = useState(false);
	const el = useRef(null);

	const Transition = (() => {
		const modules = {
			fade: Fade,
			zoom: Zoom,
			grow: Grow,
			slide: Slide,
			collapse: Collapse,
		};
		const name = transitionType || 'fade';
		return modules[name];
	})();

	useEffect(() => {
		const node = el.current;
		const observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting) setToggle(true);
			});
		});
		observer.observe(node);
		return () => observer.unobserve(node);
	}, []);

	return (
		<div ref={el}>
			<Transition in={toggle} timeout={duration || 1000} direction={direction || null}>
				<div>{getChildren(content)}</div>
			</Transition>
		</div>
	);
};
export default ThemeLoader(
	StyleLoader(
		TransitionBlock,
		makeStyles(theme => ({
			root: {},
		}))
	)
);

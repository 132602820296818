import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import SimpleBackdrop from '@material-ui/core/Modal/SimpleBackdrop';
import gray from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';

const helveticaNeueRegular = {
	fontFamily: 'Helvetica Neue',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `
		  local('Raleway'),
		  local('Raleway-Regular'),
		  url('public/fonts/31AFF9_0_0.woff2') format('woff2')
		`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const helveticaNeueBold = {
	fontFamily: 'Helvetica Neue',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 800,
	src: `
		  local('Raleway'),
		  local('Raleway-Regular'),
		  url('public/fonts/31AFF9_1_0.woff2') format('woff2')
		`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const helveticaNeueLight = {
	fontFamily: 'Helvetica Neue',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 200,
	src: `
		  local('Raleway'),
		  local('Raleway-Regular'),
		  url('public/fonts/31D183_0_0.woff2') format('woff2')
		`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const rem = 16;
const pxToRem = px => `${px / rem}rem`;

const ReplacedBackdrop = props => {
	// clear correctly so scrolling behavior can return
	useEffect(() => {
		const targetElement = document.scrollingElement || document.body;
		const target = targetElement.scrollTop;
		const { position, left, top, right, overflow } = targetElement.style;
		targetElement.style.position = 'fixed';
		targetElement.style.left = 0;
		targetElement.style.top = `${0 - target}px`;
		targetElement.style.right = 0;
		targetElement.style.overflow = 'hidden';
		return () => {
			Object.assign(targetElement.style, { position, left, top, right, overflow });
			targetElement.scrollTop = target;
		};
	});

	return <SimpleBackdrop {...props} />;
};

const theme = createMuiTheme({
	shadows: new Array(25).fill('none'),
	typography: {
		fontFamily: ['Helvetica Neue', 'Open Sans', 'Arial', 'sans-serif', 'Libre Baskerville'].join(','),
		h1: {
			fontSize: '5rem',
		},
		h2: { fontSize: pxToRem(60) },
		h3: { fontSize: pxToRem(48) },
		h4: { fontSize: pxToRem(34) },
		h5: {
			fontSize: pxToRem(24),
			fontWeight: 'bold',
			textTransform: 'uppercase',
		},
		h6: { fontSize: pxToRem(20), lineHeight: 1.2 },
		subtitle1: { fontSize: pxToRem(16) },
		subtitle2: {
			fontSize: pxToRem(14),
			fontWeight: 'bold',
		},
		overline: {
			fontSize: pxToRem(16),
			fontWeight: 'bold',
		},
		body1: { fontSize: pxToRem(20) },
		body2: { fontSize: pxToRem(16) },
	},
	overrides: {
		MuiCssBaseline: {
			'@global': {
				'@font-face': [helveticaNeueRegular, helveticaNeueBold, helveticaNeueLight],
			},
		},
	},
	palette: {
		// primary: { main: gray[600], light: gray[400] },
		primary: { main: '#afca14' },
		secondary: { main: red[600] },
		active: { main: red[600] },
		action: {
			imageHoverOpacity: 0.5,
		},
		text: {
			primary: '#000000',
			secondary: '#ffffff',
		},
	},

	background: {
		default: '#fff',
	},

	shape: {
		borderRadius: '0.5rem',
	},

	props: {
		MuiModal: {
			BackdropComponent: ReplacedBackdrop,
		},
	},
});

export default responsiveFontSizes(theme);

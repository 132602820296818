import { loadThemeModule } from '@sightworks/theme';
import { Registry } from '@sightworks/block';

export default async function preload(props) {
	if (!props) return [];

	const p = [];
	if (props.useStyles) {
		// console.log("Load: %o", props.useStyles);
		await loadThemeModule(props.useStyles);
		p.push(props.useStyles);
	}
	if (props.useTheme) {
		// console.log("Load: %o", props.useTheme);
		await loadThemeModule(props.useTheme);
		p.push(props.useTheme);
	}

	const children = Registry.getChildren(props);
	for (const child of children) {
		p.push(await preload(child));
	}

	const values = p;
	const f = (acc, value) => {
		if (Array.isArray(value)) return value.reduce(f, acc);
		acc.push(value);
		return acc;
	};
	return values.reduce(f, []);
}

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { StyleLoader } from '@sightworks/theme';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';

const CreditsBlock = ({ classes, title, content }, ref) => {
	return (
		<div className={classes.root} ref={ref}>
			{title && <Typography variant='h5'>{title}</Typography>}
			<div className={classes.content} dangerouslySetInnerHTML={{__html: content}}/>
		</div>
	);
}

const Styles = makeStyles(theme => ({
	root: {},
	content: {}
}));

export default StyleLoader(CreditsBlock, Styles);


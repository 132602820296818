import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import React, { useContext, useState, useEffect, useMemo, useCallback } from 'react';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';
import clsx from 'clsx';
import Skeleton from '@material-ui/lab/Skeleton';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import { Context } from '../../utils/query';
// import Fade from '@material-ui/core/Fade';

const TitleText = ({ content, decoration }) => {
	if (typeof content != 'string') return content;
	let p = content.indexOf(',');
	if (p == -1) return content;
	let left = content.substring(0, p);
	let right = content.substring(p);
	return <>{left}<span className={decoration}>{right}</span></>;
}

let InnerCardBlock = (props, ref) => {
	const cx = useContext(Context);
	let href = props.link ? props.link.href : null;
	if (href && props.link.preserveQuery) href = cx.extend(href);
	let linkButtonHref = props.linkButton ? props.linkButton.href : null;
	if (linkButtonHref && props.linkButton.preserveQuery) href = cx.extend(linkButtonHref);
	
	const [imageOrientation, setImageOrientation] = useState();
	const [containedImageMargin, setContainedImageMargin] = useState();

	const aspectRatioMap = {
		'4:3': '75%',
		'16:9': '56.25%',
		square: '100%',
	};

	useEffect(() => {
		if (!props.image) return;

		const img = new Image();
		img.onload = (function () {
			const ratio = aspectRatioMap[props.mediaAspectRatio];
			const ratioVal = Number(ratio.substring(0, ratio.length - 1));
			if (this.width > this.height) { 
				setImageOrientation("landscape");
				const heightDiff = Math.abs(this.height / this.width * 100 - ratioVal) / 2;
				// if (heightDiff < 5) return setContainedImageMargin(0);
				return setContainedImageMargin(heightDiff);
			};
			setImageOrientation("portrait");
			const widthDiff = Math.abs(this.width / this.height * 100 - ratioVal) / 2;
			// if (widthDiff < 5) return setContainedImageMargin(0);
			return setContainedImageMargin(widthDiff);
		})
		img.src = `/t/width=500/matte=none/scale/f${props.image}/scaled.jpg`;
	}, [])

	if (props.isLoading) {
		return (
			<Card
				className={clsx(props.classes.root, props.classes.loader)}
				ref={ref}
				style={{ '--aspect-ratio': aspectRatioMap[props.mediaAspectRatio] || aspectRatioMap['16:9'] }}
			>
				<CardActionArea className={props.classes.actionArea}>
					<Skeleton className={props.classes.media} variant="rect" />
					<CardContent className={props.classes.cardContent}>
						{props.caption && <Skeleton className={props.classes.caption} />}
						<Skeleton className={props.classes.title} />
						<Skeleton className={props.classes.content} />
						{props.content2 && <Skeleton className={props.classes.content2} />}
					</CardContent>
				</CardActionArea>
			</Card>
		);
	}

	const initialedTitle = () => {
		const split = props.contentPrepend.title.split(' ');
		const first = split[0][0].toUpperCase();
		const last = split[1][0].toUpperCase();
		return `${first}${last}`;
	};

	let fallback = null;

	if (props.contentPrepend.title) {
		fallback = props.contentPrepend.image ? null : props.contentPrepend.title[0].toUpperCase();
	}

	if (props.linkButton) return (
		<Card
			className={props.classes.root}
			ref={ref}
			style={{ '--aspect-ratio': aspectRatioMap[props.mediaAspectRatio] || aspectRatioMap['16:9'] }}
		>
			<div
				className={props.classes.actionArea}
			>
				<CardActionArea
					href={props.link ? href : void 0}
					className={props.classes.linkButtonActionArea}
					target={props.link && props.link.openInNewWindow ? '_blank' : void 0}
				>
					{props.stripe && (
						<Typography variant={props.stripeVariant} color={props.stripeColor} component="div" className={props.classes.cardStripe}>{props.stripe}</Typography>
					)}
					{(props.icon || props.image) && (
						<div className={props.classes.mediaWrap}>
							<CardMedia
								className={clsx(props.classes.media, {
									[props.classes.hidden]: props.imageFit === 'contain' && props.orientation !== 'horizontal',
								})}
								image={props.image ? `/t/width=500/matte=none/scale/f${props.image}/scaled.jpg` : undefined}
								title={props.altText}
							>{
								props.icon && <Icon className={props.classes.icon}>{props.icon}</Icon>
							}</CardMedia>
						</div>
					)}
					{props.image && (props.imageFit === 'contain' && props.orientation !== 'horizontal') && (
						<div className={props.classes.blurContainer}>
							<CardMedia
								className={props.classes.blur}
								image={props.image ? `/t/width=500/matte=none/scale/f${props.image}/scaled.jpg` : undefined}
								title={props.altText}
							/>
						</div>
					)}
					{props.image && (props.imageFit === 'contain' && props.orientation !== 'horizontal') && (
						<div className={props.classes.containedImageContainer}>
							<img
								className={imageOrientation === "portrait" ? props.classes.portraitImage : props.classes.landscapeImage}
								style={{marginTop: imageOrientation === "landscape" ? `${containedImageMargin}%` : 0}}
								src={props.image ? `/t/width=500/matte=none/scale/f${props.image}/scaled.jpg` : undefined}
								alt={props.altText}
							/>
						</div>
					)}
				</CardActionArea>
				<CardContent className={props.classes.cardContent}>
					{props.caption && (
						<ButtonBase href={props.link ? href : void 0} className={props.classes.captionButton} target={props.link && props.link.openInNewWindow ? '_blank' : void 0}>
							<Typography variant={props.captionVariant} className={props.classes.caption} color={props.captionColor}>
								{props.caption}
							</Typography>
						</ButtonBase>
					)}
					<Typography
						gutterBottom
						variant={props.titleVariant}
						color={props.titleColor}
						component="h2"
						className={props.classes.title}
					>
						<TitleText content={props.title} decoration={props.classes.decoration} />
					</Typography>
					<div className={props.classes.contentContainer}>
						{(props.contentPrepend.image || props.contentPrepend.title) && (
							<Avatar
								src={
									props.contentPrepend.image
										? `/t/square/matte=none/width=500/scale/f${props.contentPrepend.image}/scaled.jpg`
										: null
								}
								variant="rounded"
								className={props.classes.avatarRoot}
							>
								{fallback}
							</Avatar>
						)}
						<Typography
							variant={props.contentVariant}
							color={props.contentColor}
							component="p"
							className={props.classes.content}
						>
							{props.content}
						</Typography>
					</div>
					{props.content2 && (
						<Typography
							variant={props.content2Variant}
							color={props.content2Color}
							component="p"
							className={props.classes.content2}
						>
							{props.content2}
						</Typography>
					)}
					<ButtonBase href={linkButtonHref} className={props.classes.linkButtonWrapper} target={props.linkButton.openInNewWindow ? '_blank' : void 0}>
						<Typography className={props.classes.linkButton} variant={props.linkButtonVariant} color={props.linkButtonColor}>
							{props.linkButtonText}
						</Typography>
					</ButtonBase>
				</CardContent>
			</div>
		</Card>
	)

	return (
		<Card
			className={props.classes.root}
			ref={ref}
			style={{ '--aspect-ratio': aspectRatioMap[props.mediaAspectRatio] || aspectRatioMap['16:9'] }}
		>
			<CardActionArea
				href={props.link ? href : void 0}
				className={props.classes.actionArea}
				target={props.link && props.link.openInNewWindow ? '_blank' : void 0}
			>
				{props.stripe && (
					<Typography variant={props.stripeVariant} color={props.stripeColor} component="div" className={props.classes.cardStripe}>{props.stripe}</Typography>
				)}
				{(props.image || props.icon) && (
					<div className={props.classes.mediaWrap}>
						<CardMedia
							className={clsx(props.classes.media, {
								[props.classes.hidden]: props.imageFit === 'contain' && props.orientation !== 'horizontal',
							})}
							image={props.image ? `/t/width=500/matte=none/scale/f${props.image}/scaled.jpg` : undefined}
							title={props.altText}
						>{
							props.icon && <Icon className={props.classes.icon}>{props.icon}</Icon>
						}</CardMedia>
					</div>
				)}
				{props.image && (props.imageFit === 'contain' && props.orientation !== 'horizontal') && (
					<div className={props.classes.blurContainer}>
						<CardMedia
							className={props.classes.blur}
							image={props.image ? `/t/width=500/matte=none/scale/f${props.image}/scaled.jpg` : undefined}
							title={props.altText}
						/>
					</div>
				)}
				{props.image && (props.imageFit === 'contain' && props.orientation !== 'horizontal') && (
					<div className={props.classes.containedImageContainer}>
						<img
							className={imageOrientation === "portrait" ? props.classes.portraitImage : props.classes.landscapeImage}
							style={{marginTop: imageOrientation === "landscape" ? `${containedImageMargin}%` : 0}}
							src={props.image ? `/t/width=500/matte=none/scale/f${props.image}/scaled.jpg` : undefined}
							alt={props.altText}
						/>
					</div>
				)}
				<CardContent className={props.classes.cardContent}>
					{props.caption && (
						<Typography
							gutterBottom
							variant={props.captionVariant}
							color={props.captionColor}
							component="h6"
							className={props.classes.caption}
						>
							{props.caption}
						</Typography>
					)}
					<Typography
						gutterBottom
						variant={props.titleVariant}
						color={props.titleColor}
						component="h2"
						className={props.classes.title}
					>
						<TitleText content={props.title} decoration={props.classes.decoration} />
					</Typography>
					<div className={props.classes.contentContainer}>
						{(props.contentPrepend.image || props.contentPrepend.title) && (
							<Avatar
								src={
									props.contentPrepend.image
										? `/t/square/matte=none/width=500/scale/f${props.contentPrepend.image}/scaled.jpg`
										: null
								}
								variant="rounded"
								className={props.classes.avatarRoot}
							>
								{fallback}
							</Avatar>
						)}
						<Typography
							variant={props.contentVariant}
							color={props.contentColor}
							component="p"
							className={props.classes.content}
						>
							{props.content}
						</Typography>
					</div>
					{props.content2 && (
						<Typography
							variant={props.content2Variant}
							color={props.content2Color}
							component="p"
							className={props.classes.content2}
						>
							{props.content2}
						</Typography>
					)}
				</CardContent>
			</CardActionArea>
		</Card>
	);
};

InnerCardBlock = React.forwardRef(InnerCardBlock);

const stripe = {
	cardStripe: {
		position: 'absolute',
		top: 18,
		right: -22,
		position: 'absolute',
		transform: 'rotate(45deg)',
		width: 100,
		textAlign: 'center',
		transformOrigin: '50% 50%'
	}
}

const VerticalStyles = makeStyles(
	theme => ({
		...stripe,
		root: {},
		actionArea: {},
		media: {
			paddingTop: 'var(--aspect-ratio)',
		},
		mediaWrap: {},
		cardContent: {},
		caption: {},
		title: {},
		content: {},
		content2: {},
		blur: {
			paddingTop: 'var(--aspect-ratio)',
			backgroundSize: 'cover',
			position: 'absolute',
			width: '100%',
			top: 0,
			opacity: 0.7,
			filter: 'blur(30px)',
		},
		blurContainer: {
			position: 'absolute',
			top: 0,
			width: '100%',
			paddingTop: 'var(--aspect-ratio)',
			overflow: 'hidden',
		},
		containedImageContainer: {
			position: 'absolute',
			top: 0,
			width: '100%',
			paddingTop: 'var(--aspect-ratio)',
			overflow: 'hidden',
			justifyContent: 'center',
			display: 'flex',
		},
		hidden: {
			opacity: 0,
		},
		landscapeImage: {
			height: 'auto',
			width: '100%',
			position: 'absolute',
			top: 0,
		},
		portraitImage: {
			height: '100%',
			width: 'auto',
			position: 'absolute',
			top: 0,
		},
		image: {
			height: '100%',
			width: 'auto',
			position: 'absolute',
			top: 0,
		},
	}),
	{ name: 'SwVerticalCard' }
);

const StackedStyles = makeStyles(
	theme => ({
		...stripe,
		root: {
			width: '100%'
		},
		actionArea: {
			position: 'relative'
		},
		mediaWrap: {
		},
		media: {
			paddingTop: 'var(--aspect-ratio)',
		},
		cardContent: {
			position: 'absolute',
			bottom: 0,
			left: 0,
			right: 0
		},
		caption: {},
		title: {},
		content: {},
		content2: {},
		blur: {
			paddingTop: 'var(--aspect-ratio)',
			backgroundSize: 'cover',
			position: 'absolute',
			width: '100%',
			top: 0,
			opacity: 0.7,
			filter: 'blur(30px)',
		},
		blurContainer: {
			position: 'absolute',
			top: 0,
			width: '100%',
			paddingTop: 'var(--aspect-ratio)',
			overflow: 'hidden',
		},
		containedImageContainer: {
			position: 'absolute',
			top: 0,
			width: '100%',
			paddingTop: 'var(--aspect-ratio)',
			overflow: 'hidden',
			justifyContent: 'center',
			display: 'flex',
		},
		hidden: {
			opacity: 0,
		},
		image: {
			height: '100%',
			width: 'auto',
			position: 'absolute',
			top: 0,
		},
		linkButton: {},
		linkButtonWrapper: {},
		linkButtonActionArea: {
			position: 'relative'
		}
	}),
	{ name: 'SwStackedCard' }
);

const HorizontalStyles = makeStyles(
	theme => ({
		...stripe,
		root: {
			'--image-width': '150px',
			minHeight: 'var(--image-width)'
		},
		actionArea: {
			paddingLeft: 'var(--image-width)',
			position: 'relative',
			display: 'flex',
		},
		linkButtonActionArea: {
			position: 'absolute',
			top: 0,
			left: 0,
			width: 'var(--image-width)',
			bottom: 0
		},
		linkButton: {},
		linkButtonWrapper: {},
		mediaWrap: {
			position: 'absolute',
			top: 0,
			left: 0,
			bottom: 0,
			width: 'var(--image-width)',
		},
		media: {
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			paddingTop: '100%',
			objectFit: 'cover',
			objectPosition: 'top center'
		},
		icon: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			fontSize: '2rem',
			transform: 'translate(-50%, -50%)',
			color: '#fff'
		},
		cardContent: {
			// marginTop: 'auto',
			// marginBottom: 'auto',
			width: '100%'
		},
		caption: {},
		title: {},
		content: {},
		content2: {},
		blur: {
			backgroundSize: 'cover',
			height: '100%',
			opacity: 0.7,
			filter: 'blur(30px)',
		},
		blurContainer: {
			top: 0,
			height: '100%',
			position: 'absolute',
			width: '30%',
			overflow: 'hidden',
		},
		containedImageContainer: {
			position: 'absolute',
			top: 0,
			width: '30%',
			height: '100%',
			display: 'flex',
		},
		imageContainerTest: {
			height: '100%',
			width: '100%',
		},
		hidden: {
			opacity: 0,
		},
		landscapeImage: {
			height: 'auto',
			width: '100%',
			position: 'absolute',
			top: 0,
		},
		portraitImage: {
			height: '100%',
			width: 'auto',
			position: 'absolute',
			top: 0,
		},
		image: {
			height: 'auto',
			width: '100%',
			position: 'absolute',
			top: 0,
		}
	}),
	{ name: 'SwHorizontalCard' }
);


let HorizontalCardBlock = (props, ref) => <InnerCardBlock {...props} ref={ref} />;
let VerticalCardBlock = (props, ref) => <InnerCardBlock {...props} ref={ref} />;
let StackedCardBlock = (props, ref) => <InnerCardBlock {...props} ref={ref} />;

HorizontalCardBlock = ThemeLoader(StyleLoader(HorizontalCardBlock, HorizontalStyles));
VerticalCardBlock = ThemeLoader(StyleLoader(VerticalCardBlock, VerticalStyles));
StackedCardBlock = ThemeLoader(StyleLoader(StackedCardBlock, StackedStyles));

let Cards = {
	horizontal: HorizontalCardBlock,
	vertical: VerticalCardBlock,
	stacked: StackedCardBlock
};

const RootCardBlock = (props, ref) => {
	const [prevState, setPrevState] = useState(props);
	const updateState = useCallback(() => {
		setPrevState(props);
	}, [props]);
	const theme = useTheme();
	const loadingChanged = prevState.isLoading != props.isLoading;
	const [transitionState, setTransitionState] = useState('none');
	useEffect(() => {
		const ignore = false;
		if (loadingChanged) {
			switch (transitionState) {
				case 'none':
					setTransitionState('init');
					break;

				case 'init':
					setTransitionState('start');
					break;

				case 'start':
					setTimeout(() => {
						setTransitionState('end');
					}, 300);
					break;

				case 'end':
					setPrevState(props);
					break;

				default:
					setTransitionState('init');
			}
		} else if (transitionState == 'end') {
			setTransitionState('none');
		}
	}, [props, loadingChanged, transitionState]);

	let cardLoading;
	let cardDone;
	if (props.isLoading) cardLoading = props;
	else cardDone = props;
	if (prevState.isLoading && !cardLoading) cardLoading = prevState;
	else if (!prevState.isLoading && !cardDone) cardDone = prevState;

	return (
		<div
			className={clsx(props.classes.transitionContainer, {
				[props.classes.loading]: transitionState == 'none' ? props.isLoading : prevState.isLoading,
				[props.classes.transitionInit]: transitionState == 'init' || transitionState == 'start',
				[props.classes.transitionRun]: transitionState == 'start',
				[props.classes.transitionDone]: transitionState == 'end',
			})}
		>
			{cardLoading && (
				<div className={clsx(props.classes.transitionItem, props.classes.transitionLoader)}>
					{React.createElement(
						Cards[cardLoading.orientation || 'vertical'],
						cardLoading
					)}
				</div>
			)}
			{cardDone && (
				<div className={clsx(props.classes.transitionItem, props.classes.transitionTarget)}>
					{React.createElement(Cards[cardDone.orientation || 'vertical'], {
						...cardDone,
						ref,
					})}
				</div>
			)}
		</div>
	);
};

const RootCardStyles = makeStyles(
	theme => ({
		stripe: {},
		root: {},
		actionArea: {},
		media: {},
		cardContent: {
			'& .MuiTypography-subtitle1': {
				lineHeight: 1,
			},
		},
		caption: {},
		title: {
		},
		content: {},
		avatarRoot: {
			marginRight: theme.spacing(1),
			fontSize: theme.typography.subtitle1.fontSize,
			fontWeight: 'bold',
		},
		contentContainer: {
			display: 'flex',
			alignItems: 'center',
		},
		content2: {},
		transitionContainer: {
			position: 'relative',
			display: 'grid',
			gridTemplateColumns: 'auto',
			gridTemplateRows: 'auto',
		},
		transitionItem: {
			gridColumn: 1,
			gridRow: 1,
			position: 'relative',
			'& + $transitionItem': {
				zIndex: 1,
			},
			opacity: 0,
			display: 'flex',
			'& > $root': {
				flex: '1 1 auto',
				display: 'flex',
				'& > $actionArea': {
					flex: '1 1 auto'
				}
			}
		},
		transitionLoader: {},
		transitionTarget: {
			opacity: 1,
		},
		loading: {
			'& > $transitionLoader': { opacity: 1 },
			'& > $transitionTarget': { opacity: 0 },
		},
		transitionInit: {
			'& > $transitionItem': {
				transition: 'opacity .3s ease',
			},
		},
		transitionRun: {
			'& > $transitionLoader': {
				opacity: 1,
			},
			'& > $transitionTarget': {
				opacity: 0,
			},
			'&$loading': {
				'& > $transitionLoader': {
					opacity: 0,
				},
				'& > $transitionTarget': {
					opacity: 1,
				},
			},
		},
		transitionDone: {
			'& > $transitionLoader': {
				opacity: 1,
			},
			'& > $transitionTarget': {
				opacity: 0,
			},
			'&$loading': {
				'& > $transitionLoader': {
					opacity: 0,
				},
				'& > $transitionTarget': {
					opacity: 1,
				},
			},
		},
		linkButton: {},
		linkButtonWrapper: {},
		linkButtonActionArea: {},
		mediaWrap: {},
		icon: {},
		decoration: {}
	}),
	{ name: 'SwCard' }
);

export default ThemeLoader(StyleLoader(RootCardBlock, RootCardStyles));

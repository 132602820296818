import * as shrinkingIcon from './shrinking-icon/browser';
import * as margin from './margin/browser';
import * as padding from './padding/browser';
import * as spacer from './spacer/browser';
import * as imageSize from './image-size/browser';
import * as listImageAsForeground from './list-image-as-foreground/browser';

export default {
	'shrinking-icon': shrinkingIcon,
	margin,
	padding,
	spacer,
	'image-size': imageSize,
	'list-image-as-foreground': listImageAsForeground
}


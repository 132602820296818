import React, { useContext } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { StyleLoader } from '@sightworks/theme';
import Styled from '../../utils/styled';
import * as Registry from '../../registry';
import getChildren from '../../utils/children';
import { useLinkAttributes } from '../../utils/linkContext';

const RawIconButtonBlock = ({ button, classes, content, link }, ref) => {
	const lp = useLinkAttributes(link);

	return (
		<IconButton {...button} {...lp} classes={classes} ref={ref}>
			{getChildren(content)}
		</IconButton>
	);
};

export default StyleLoader(RawIconButtonBlock);

import React from 'react';
import Typography from '@material-ui/core/Typography';

const DisplayedTextField = ({ classes, text }) => <Typography className={classes.displayedText} variant="body1">{text}</Typography>;

export default DisplayedTextField;
export const Style = {
	displayedText: {}
};


import EventEmitter from 'events';
import { promisify } from 'util';

let instance = null;

const fullUrlRe = /^(https?):\/\/([^\/?#]+)(\/[^?#]*)?(\?[^#]*)?(#.*)?$/;
const itemHasProto = /^([a-z0-9-]+:)/;
const onlyPathRe = /^([^?#]*)(\?[^#]*)?(#.*)?$/;
const quotedItemMatch = /\"([^\"]+)\"/;

export default class Platform extends EventEmitter {
	constructor() {
		super();
	}

	static create() {
		if (!instance) {
			instance = new Platform();
		}
		return instance;
	}

	constructURLFromParts(url, origin, preserveFragment = false) {
		// If a protocol is specified, the URL is complete.
		if (itemHasProto.test(url)) return url;

		// If it starts "//", it's one of those obnoxious "no protocol but everything else" URLs.
		let originItems = origin.match(fullUrlRe);
		if (onlyPathRe.test(url) && url.startsWith('/\/')) {
			return `${originItems[1]}:${url}`;
		}
		let urlParts = {
			scheme: originItems[1],
			host: originItems[2],
			path: originItems[3] || '',
			query: originItems[4] || '',
			frag: originItems[5] || ''
		};
		let parsedItems = url.match(this.onlyPathRe);
		urlParts.query = parsedItems[2] || '';
		urlParts.frag = parsedItems[3] || '';
		if (parsedItems[1].startsWith('/')) {
			urlParts.path = parsedItems[1];
		} else {
			let op = urlParts.path.split('/').filter(v => !!v);
			let np = parsedItems[1].split('/').filter(v => !!v);
			if (urlParts.path[urlParts.path.length - 1] != '/' && np.length) op.pop();

			for (let fragment of np) {
				if (fragment == '.') continue;
				if (fragment == '..') { op.pop(); continue; }
				op.push(fragment);
			}

			urlParts.path = `/${op.join('/')}${parsedItems[1][parsedItems[1].length - 1] == '/' ? '/' : ''}`;
		}

		return `${urlParts.scheme}:/\/${urlParts.host}${urlParts.path}${urlparts.query}${preserveFragment ? urlParts.frag : ''}`;
	}

	tokenizeQueryString(queryString) {
		let result = {
			term: []
		};

		let m, terms = [];
		while (m = queryString.match(quotedItemMatch)) {
			terms.push(m[1]);
			queryString = queryString.replace(m[0], '');
		}

		queryString = queryString.replace(/(^\s+|\s+$)/g, '').replace(/\s+/, ' ').split(' ');
		terms.push(...queryString);

		for (let term of terms) {
			if (!term) continue;
			if (term.indexOf(':') != -1) {
				let [ , key, value ] = /^([^:]+):(.*)$/.exec(term);
				key = key.toLowerCase();
				if (!result[key]) result[key] = [];
				result[key].push(value);
			} else {
				result.term.push(term);
			}
		}

		return result;
	}

	makeQueryString(tokens) {
		let result = [];
		for (let [ type, values ] of Object.entries(tokens)) {
			if (type == 'term') result.push(...values);
			else result.push(...values.map(value => `${type}:${value}`));
		}
		return result.map(value => value.indexOf(' ') == -1 ? value : `"${value}"`).join(' ');
	}

	getCustomerId() {
		// FIXME
		return 'pages';
	}
}


import HomeTile from './home-tile';
import BasicTile from './basic-tile';
import HomeBar from './home-bar';
import HeaderBanner from './header-banner';
import HomeHeaderBanner from './home-header-banner';
import SmallHeaderBanner from './small-header-banner';
import SmallHeaderBannerAlt from './small-header-banner-alt';
import HomeEnrollGrid from './home-enroll-grid';
import TestimonialCarousel from './testimonial-carousel';
import NavDrawer from './nav-drawer';
import MobileNavButton from './mobile-nav-button';
import LogoCards from './logo-cards';
import SiteFooter from './site-footer';
import EventCard from './event-card';
import Calendar from './calendar';
import MediaEmbed from './media-embed';
import BottomLinks from './bottom-links';
import LinkCard from './link-card';
import LinkCard2 from './link-card-2';
import OpaqueIconButton from './opaque-icon-button';
import GreyButton from './grey-button';
import Bold from './bold';
import TuitionTable from './tuition-tables';
import SiteNavigation from './site-navigation';
import PersonDetailHeader from './person-detail-header';
import NavButtonWithEmphasis from './emphasis-nav-button';
import ArticleCard from './article-card';
import ArticleCarousel from './article-carousel';
import ArticleCarousel2 from './article-carousel2';
import JobDetailsGrid from './job-details-grid';
import LocationDetailsGrid from './location-details-grid';
import CenteredButton from './centered-button';
import LocationImage from './location-image';
import LocationTile from './location-tile';
import LocationCarousel from './location-carousel';
import SmallPeopleCardList from './small-people-card-list';
import MarginToPadding from './margin-to-padding';
import DocumentCardList from './document-card-list';
import SlideUpContent from './slide-up-content';
import SlideLeftContent from './slide-left-content';
import LandingHeaderBanner from './landing-header-banner';
import LandingSquare from './landing-square';

export default {
	'home-tile': {
		title: 'Home Page Tiles',
		style: HomeTile,
	},
	'basic-tile': {
		title: 'Basic Tiles',
		style: BasicTile,
	},
	'home-bar': {
		title: 'Home Page "Keep Exploring" bar',
		style: HomeBar,
	},
	'header-banner': {
		title: 'Header Banner',
		style: HeaderBanner,
	},
	'landing-header-banner': {
		title: 'Landing Header Banner',
		style: LandingHeaderBanner,
	},
	'landing-square': {
		title: 'Landing Square',
		style: LandingSquare,
	},
	'home-header-banner': {
		title: 'Home Header Banner',
		style: HomeHeaderBanner,
	},
	'small-header-banner': {
		title: 'Small Header Banner',
		style: SmallHeaderBanner,
	},
	'small-header-banner-alt': {
		title: 'Small Header Banner (alt)',
		style: SmallHeaderBannerAlt,
	},
	'home-enroll-grid': {
		title: 'Home - "Ready to Enroll" grid',
		style: HomeEnrollGrid,
	},
	'testimonial-carousel': {
		title: 'Testimonial Carousel',
		style: TestimonialCarousel,
	},
	'nav-drawer': {
		title: 'Navigation Drawer Item',
		style: NavDrawer,
	},
	'mobile-nav-button': {
		title: 'Mobile Nav Button',
		style: MobileNavButton,
	},
	'emphasis-nav-button': {
		title: 'Nav Button with Emphasis',
		style: NavButtonWithEmphasis,
	},
	'site-footer': {
		title: 'Site Footer',
		style: SiteFooter,
	},
	'event-card': {
		title: 'Event Card',
		style: EventCard,
	},
	'slide-up-content': {
		title: 'Slide Up Content',
		style: SlideUpContent,
	},
	'slide-left-content': {
		title: 'Slide Left Content',
		style: SlideLeftContent,
	},
	calendar: {
		title: 'Calendar',
		style: Calendar,
		onlyOn: ['calendar'],
	},
	'media-embed': {
		title: 'Embedded media (<iframe> or <video>)',
		style: MediaEmbed,
		onlyOn: ['embed', 'raw/embed'],
	},
	'bottom-links': {
		title: 'Bottom Links',
		style: BottomLinks,
	},
	'logo-cards': {
		title: 'Logo Cards',
		style: LogoCards,
	},
	'article-card': {
		title: 'Article Card',
		style: ArticleCard,
	},
	'article-carousel': {
		title: 'Article Carousel',
		style: ArticleCarousel,
	},
	'article-carousel2': {
		title: 'Article Carousel (no border)',
		style: ArticleCarousel2,
	},
	'link-card': {
		title: 'Link Card',
		style: LinkCard,
	},
	'link-card-2': {
		title: 'Link Card (2)',
		style: LinkCard2,
	},
	'opaque-icon-button': {
		title: 'Opaque (white background) icon button',
		style: OpaqueIconButton,
		onlyOn: ['raw/icon-button', 'button'],
	},
	'grey-button': {
		title: 'Transparent grey button',
		style: GreyButton,
		onlyOn: ['raw/button', 'button'],
	},
	bold: { title: 'Bold', style: Bold },
	'tuition-tables': {
		title: 'Tuition table',
		style: TuitionTable,
	},
	'site-navigatgion': { title: 'Site Navigation', style: SiteNavigation },
	'person-detail-header': { title: 'Person detail header', style: PersonDetailHeader },
	'job-details-grid': { title: 'Job Details Grid', style: JobDetailsGrid },
	'location-details-grid': { title: 'Location Details Grid', style: LocationDetailsGrid },
	'centered-button': { title: 'Centered Button', style: CenteredButton },
	'location-image': { title: 'Location Image', style: LocationImage },
	'location-tile': { title: 'Location Tile', style: LocationTile },
	'location-carousel': { title: 'Location Carousel', style: LocationCarousel },
	'small-people-card-list': { title: 'People Card List', style: SmallPeopleCardList },
	'margin-to-padding': { title: 'Move top / bottom margin to padding', style: MarginToPadding },
	'document-card-list': { title: 'Document Card List', style: DocumentCardList },
};

import React, { useState, useMemo, useCallback, createContext, useContext } from 'react';
import QueryProvider from '../../utils/query';
import NavigatorProvider from '../../utils/navigator';

const FilterContext = createContext(null);

export { FilterContext as Context };

const Filter = ({ itemContext, children }) => {
	let defaultOpen = itemContext.open || false;
	if (typeof history === 'object' && history.state && history.state[`${itemContext.key}_sort`]) {
		defaultOpen = history.state[`${itemContext.key}_sort`];
	}
	const [open, setOpen] = useState(defaultOpen || false);
	const [recordList, setRecordList] = useState(null);
	const [columnSize, setColumnSize] = useState('auto');
	const [filterInDrawer, setFilterInDrawer] = useState(false);
	
	const setRecordListDebuggable = useCallback(
		(...args) => {
			setRecordList(...args);
		},
		[setRecordList]
	);

	const toggle = useCallback(() => {
		setOpen(!open)
		setTimeout(() => window.dispatchEvent(new Event('resize')), 400);
	}, [open]);
	const filterBar = useMemo(
		() => ({
			...itemContext,
			open,
			toggle,
			recordList,
			setRecordList: setRecordListDebuggable,
			columnSize,
			setColumnSize,
			filterInDrawer,
			setFilterInDrawer
		}),
		[open, toggle, itemContext, recordList, columnSize]
	);

	// filterBar.columnSize = columnSize;
	
	console.log(itemContext);

	const queryData = useMemo(
		() => {
			let r = {
				...(itemContext.selectedTags && itemContext.selectedTags.length && { [`${itemContext.key}_tag`]: itemContext.selectedTags }),
				...(itemContext.currentSort != itemContext.defaultSort && {
					[`${itemContext.key}_sort`]: itemContext.currentSort,
				}),
				...(open && { [`${itemContext.key}_open`]: '1' }),
			};
			for (let [ fieldName, data ] of Object.entries(itemContext.fields)) {
				if (data.selected.length) {
					r[`${itemContext.key}_field_${fieldName}`] = data.selected;
				}
			}
			return r;
		},
		[open, itemContext]
	);

	const navigate = useCallback(async () => {
		if (recordList) {
			await recordList.setLoading();
		}
		return false;
	}, [recordList]);

	return (
		<FilterContext.Provider value={filterBar}>
			<NavigatorProvider navigate={navigate}>
				<QueryProvider query={queryData}>{children}</QueryProvider>
			</NavigatorProvider>
		</FilterContext.Provider>
	);
};

const WithButton = ({ children }) => {
	const context = useContext(FilterContext);
	const newFilterBar = useMemo(
		() => ({
			...context,
			hasButton: true,
		}),
		[context]
	);

	return <FilterContext.Provider value={newFilterBar}>{children}</FilterContext.Provider>;
};

export default Filter;
export { WithButton };

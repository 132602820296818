import React, { useContext, createContext, useMemo } from 'react';

class Query {
	parameters = {};
	_q = null;
	constructor(query) {
		if (query)
			Object.assign(this.parameters, query.parameters);
	}

	get() {
		if (this._q !== null) return this._q;
		this._q = this.extend("?");
		return this._q;
	}

	parse(query) {
		let pp = {};
		query = query.split('&').filter(v => !!v);
		query.forEach(v => {
			v = v.split('=');
			let key = v.shift();
			let val = v.join('=');
			key = decodeURIComponent(key);
			if (key.substr(-2) == '[]') {
				key = key.substring(0, key.length - 2);
				if (!pp[key]) pp[key] = [];
				pp[key].push(decodeURIComponent(val));
			} else {
				pp[key] = decodeURIComponent(val);
			}
		});
		return pp;
	}
	
	extend(url = "?") {
		let r = [];
		let [ path, ...query ] = url.split('?');
		query = query.join('?');
		let pp = this.parse(query);
		Object.assign(pp, this.parameters);
		for (let key in pp) {
			if (Array.isArray(pp[key])) {
				r = r.concat(pp[key].map(value => `${encodeURIComponent(`${key}[]`)}=${encodeURIComponent(value)}`));
			} else {
				r.push(`${encodeURIComponent(key)}=${encodeURIComponent(pp[key])}`);
			}
		}
		r = r.join('&');
		if (r) return path + '?' + r;
		return path;
	}

	add(params) {
		this._q = null;
		if (typeof params == 'string')
			params = this.parse(params);
		Object.assign(this.parameters, params);
	}
}

const QueryContext = createContext(new Query());

const QueryProvider = ({ query, children }) => {
	let currentContext = useContext(QueryContext);
	let querySource = useMemo(() => {
		let querySource = new Query(currentContext);
		querySource.add(query);
		return querySource;
	}, [ currentContext, query ]);
	return <QueryContext.Provider value={querySource}>{children}</QueryContext.Provider>
}

export default QueryProvider;
export { QueryContext as Context };


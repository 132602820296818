import DisplayedText from './displayedText/browser';
import Dropdown from './dropdown/browser';
import Header from './header/browser';
import Location from './location/browser';
import Rating from './rating/browser';
import TextField from './textField/browser';
import EmailAddress from './emailAddress/browser';
import TextArea from './textArea/browser';
import Spacer from './spacer/browser';
import FileUploadField from './fileUploadField/browser';

export default {
	displayedText: DisplayedText,
	dropdown: Dropdown,
	header: Header,
	location: Location,
	rating: Rating,
	textField: TextField,
	emailAddress: EmailAddress,
	textArea: TextArea,
	spacer: Spacer,
	fileUploadField: FileUploadField
};

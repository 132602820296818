//unused can remove

export default theme => ({
	root: {
		flexBasis: '350px',
		textAlign: 'center',
	},
	actionArea: {
		height: '350px',
		[theme.breakpoints.up('xl')]: {
			height: '600px',
		},
		[theme.breakpoints.only('lg')]: {
			height: '400px',
		},
	},
	cardContent: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		height: '35%',
		textAlign: 'left',
		marginLeft: theme.spacing(1),
	},
	content: {
		fontWeight: 'bold',
	},
	title: {
		// fontSize: `${theme.typography.h4.fontSize} !important`,
	},
	media: {
		paddingTop: '0%',
		height: '65%',
	},
});

import React, { useState, useEffect } from 'react';
import { StyleLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/styles';
import Styled from '../../utils/styled';

const RawEmbedBlock = ({ classes, content, afterLoad }, ref) => {
	const [visible, setVisible] = useState(!afterLoad);
	useEffect(() => {
		setVisible(true);
	}, []);

	return (
		<div className={classes.root} ref={ref} {...(visible ? { dangerouslySetInnerHTML: { __html: content } } : {})} />
	);
};

export default StyleLoader(
	RawEmbedBlock,
	makeStyles(
		theme => ({
			root: {},
		}),
		{ name: 'SWEmbed' }
	)
);

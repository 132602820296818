import PageBlock from './special/page/block';
import { Platform } from '@sightworks/datastore';
import { makeStyles } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

const Registry = new Map();
const theme = null;
const NamedStyles = new Map();
const NamedThemes = new Map();

/**
 * Add the list of types to the registry.
 *
 * @param {object|Iterable.<Array.<String, BlockType>>} types The types to add
 */
export function addBlocks(types) {
	if (types && typeof types == 'object') {
		if (!(Symbol.iterator in types)) types = Object.entries(types);
	}
	for (let [ typeName, type ] of types) {
		Registry.set(typeName, type);
		if (type.record) {
			Platform.DbItemTypes.add(type.record);
		}
	}
}

/**
 * Retrieve the list of all block types
 *
 * @return {Iterable.<Array.<String, BlockType>>} The types in the registry
 */
export function *allBlocks() {
	yield* Registry[Symbol.iterator]();
}

/**
 * Retrieve a single block from the registry based on the properties of the block.
 *
 * @param {object} props The block properties. This must have a property 'type'.
 * @return {function(new:React.Component)} The React component representing the block.
 */
export function getBlock(props, raw = false) {
	let b = Registry.get(props.type);
	return (raw ? b.rawComponent : null) || b.component;
	// return Registry.get(props.type).component;
}

/** 
 * Given the props for a single block, retrieve it's children.
 *
 * @param {object} props The block properties. This must have a property 'type'.
 * @return {Array.<object>} The props of the child blocks.
 */
export function getChildren(props) {
	return Registry.get(props.type).getChildren(props);
}

/**
 * Retrieve the entire block type from the registry based on it's record.
 *
 * @param {Record} record The block record from the '<channelKey>_blocks' app.
 * @return {BlockType} The block type
 */
export function getBlockInfo(record) {
	return Registry.get(record.child_type);
}

export function getTheme() {
	return PageBlock.defaultTheme;
}

export function setTheme(t) {
	// console.log(`Set theme: `, t);
	PageBlock.defaultTheme = t;
}

export function addNamedStyles(items) {
	if (items && typeof items == 'object') {
		if (!(Symbol.iterator in items)) items = Object.entries(items);
	}

	for (let [ id, style ] of items) {
		NamedStyles.set(id, style);
	}
}

export function *allNamedStyles() { yield* NamedStyles[Symbol.iterator](); }
export function getNamedStyle(name) { return NamedStyles.get(name); }

const placeholderStyle = makeStyles({ root: {} }, { name: 'Placeholder' });

export function makeNamedStyle(name) {
	let s = getNamedStyle(name);
	if (s) {
		if (!s.muiStyles) {
			s.muiStyles = makeStyles(s.style, { name });
		}
		return s.muiStyles;
	}
	return placeholderStyle;
}

export function addNamedThemes(items) {
	if (items && typeof items == 'object') {
		if (!(Symbol.iterator in items)) items = Object.entries(items);
	}

	for (let [ id, theme ] of items) {
		NamedThemes.set(id, theme);
	}
}

export function *allNamedThemes() { yield* NamedThemes[Symbol.iterator](); }
export function getNamedTheme(name) { return NamedThemes.get(name); }

const Cache = new Map();
export function makeNamedTheme(name, currentTheme) {
	let tgt = getNamedTheme(name);
	if (tgt) {
		let c = Cache.get(currentTheme);
		if (!c) {
			c = new Map();
			Cache.set(currentTheme, c);
		}
		if (c.has(tgt)) {
			return c.get(tgt);
		}
		let out = tgt.theme;
		if (typeof tgt.theme == 'function') out = tgt.theme(currentTheme || {});
		c.set(tgt, createMuiTheme(out));
		return c.get(tgt);
	}
	return currentTheme;
}

let updater = null;
export function update(href) {
	if (updater) updater(href);
	else location.href = href;
}

export function setUpdater(f) {
	updater = f;
}


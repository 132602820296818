import React, { Fragment, useState, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/styles';
import Drawer from '@material-ui/core/Drawer';
import getChildren from '../../utils/children';

const DrawerBlock = ({ content, drawer, classes }, ref) => {
	let [ open, setOpen ] = useState(false);
	let [ element, setElement ] = useState(null);

	let children = getChildren(content);
	if (drawer.variant == 'permanent') {
		return (
			<Drawer {...drawer} classes={classes}>
				{children}
			</Drawer>
		);
	}

	if (!element) {
		return (
			React.cloneElement(children[0], {
				...children[0].props,
				button: {
					...children[0].props.button,
					onClick() {
						setOpen(!open)
					}
				}
			})
		);
	}

	let d = (
		<Drawer {...drawer} open={open} onClose={() => setOpen(false)} ref={ref} classes={classes} elevation={1}>
			{children.slice(1)}
		</Drawer>
	);

	return (
		<Fragment>
			{
				React.cloneElement(children[0], {
					...children[0].props,
					button: {
						...children[0].props.button,
						onClick() {
							setOpen(!open)
						}
					}
				})
			}
			{
				d
			}
		</Fragment>
	);
}

const DrawerCustomStyle = makeStyles({
});

const ThemedDrawerBlock = ThemeLoader(StyleLoader(DrawerBlock, DrawerCustomStyle));

export default ThemedDrawerBlock;



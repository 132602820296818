import React from 'react';
import Typography from '@material-ui/core/Typography';
import { StyleLoader } from '@sightworks/theme';

const PlaintextBlock = ({ classes, text }, ref) => (
	<Typography {...text} classes={classes} ref={ref}/>
);

export default StyleLoader(PlaintextBlock);


import React from 'react';
const image = {
	component: ({ target, detail, next }) => {
		if (detail.square) {
			return (
				<div style={{ backgroundImage: `url(${target.img.src})`, paddingTop: '100%', borderRadius: detail.round ? '100%' : '', backgroundPosition: detail.backgroundPosition || '50% 50%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}/>
			);
		}
		return next(target);
	}
}

export { image };

import React, { useState, useCallback, useContext } from 'react';
import { ThemeLoader } from '@sightworks/theme';
import { createPortal } from 'react-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '../../components/DialogContent';
import DialogTitle from '../../components/DialogTitle';
import DialogActions from '../../components/DialogActions';
import getChildren from '../../utils/children';
import Provider from '../../utils/linkContext';
import ActionContextProvider, { Context as ActionContext } from '../../utils/actionContext';

const CustomDialogActions = ({ onClose }) => {
	const cx = useContext(ActionContext);
	const f = useCallback(
		node => {
			console.log(node);
			cx.setTarget(node);
		},
		[cx]
	);
	return (
		<DialogActions onClose={onClose}>
			<div ref={f} />
		</DialogActions>
	);
};

let SWDialog = ({ open, close, dialogTitle, content }, ref) => (
	<Dialog open={open} onClose={close} maxWidth="sm" fullWidth ref={ref}>
		<DialogTitle onClose={close}>{dialogTitle}</DialogTitle>
		<DialogContent>{getChildren(content)}</DialogContent>
		<CustomDialogActions onClose={close} />
	</Dialog>
);

let ThemedDialog = ThemeLoader(SWDialog);

const DialogBlock = (props, ref) => {
	const [open, setOpen] = useState(false);
	const close = useCallback(() => setOpen(false), [setOpen]);
	const show = useCallback(() => setOpen(true), [setOpen]);

	return (
		<ActionContextProvider>
			<Provider open={show} close={close}>
				{getChildren(props.button)}
			</Provider>
			<ThemedDialog open={open} close={close} dialogTitle={props.dialogTitle} content={props.content} useNamedTheme="default"/>
		</ActionContextProvider>
	);
};

const ThemedDialogBlock = ThemeLoader(DialogBlock);
export default ThemedDialogBlock;

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { StyleLoader } from '@sightworks/theme';
import clsx from 'clsx';

const CallOutBlock = ({ classes, content, variant }, ref) => {
	return (
		<div className={clsx(classes.root, {
			[classes.tip]: variant == 'tip',
			[classes.info]: variant == 'info',
			[classes.note]: variant == 'note'
		})} ref={ref} dangerouslySetInnerHTML={{__html: content}}/>
	);
}

const Styles = makeStyles(theme => ({
	root: {
		border: '0px solid',
		borderRadius: theme.shape.borderRadius,
		padding: theme.spacing(4),
	},

	info: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText
	},

	tip: {
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.contrastText
	},

	note: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.contrastText
	}
}));

export default StyleLoader(CallOutBlock, Styles);


import { makeStyles } from '@material-ui/styles';

const f = makeStyles(theme => ({
	root: {
		flex: 1
	}
}), { name: 'flex-spacer', index: 10000 });

const root = {
	styles: detail => f
}

export { root };

import React from 'react';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import Toolbar from '@material-ui/core/Toolbar';
import getChildren from '../../utils/children';

let ToolbarBlock = ({ content, toolbar, classes }, ref) => {
	return (
		<Toolbar ref={ref} classes={classes} {...toolbar}>
			{getChildren(content)}
		</Toolbar>
	);
};

export default ThemeLoader(StyleLoader(ToolbarBlock));



import React from 'react';
import { StyleLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Styled from '../../utils/styled';

const ImageBlock = ({ classes, img, align }, ref) => {
	return (
		<img ref={ref} data-align={align} className={clsx(classes.root, classes[align])} alt={img.alt} src={img.src} />
	);
};
const Styles = makeStyles(
	theme => ({
		root: { width: 'max-content', display: 'block', maxWidth: '100%' },
		left: { marginRight: 'auto !important', marginLeft: '0 !important' },
		center: { marginLeft: 'auto !important', marginRight: 'auto !important' },
		right: { marginLeft: 'auto !important', marginRight: '0 !important' },
		justify: { marginLeft: 'auto !important', marginRight: 'auto !important', width: '100%' },
	}),
	{ name: 'SwRawImage' }
);

export default StyleLoader(ImageBlock, Styles);

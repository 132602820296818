export default theme => ({
	root: {
		'& .MuiIcon-root': { marginRight: theme.spacing(0.5) },
		'& .MuiIconButton-root .MuiIcon-root': { marginRight: 0 },
		'& .MuiToolbar-root > .MuiButton-root.MuiButton-text': {
			alignSelf: 'stretch',
		},
		'& .MuiToolbar-root > .MuiButton-root.MuiButton-sizeLarge': {
			paddingLeft: theme.spacing(6),
			paddingRight: theme.spacing(6),
		},
		'& .MuiToolbar-root > .MuiButton-root.MuiButton-text .MuiTypography-root': {
			fontWeight: 'bold',
		},
		'& .MuiToolbar-root > .MuiBox-root ~ .MuiButton-root.MuiButton-text': {
			alignSelf: 'center',
			marginLeft: theme.spacing(1),
		},
		'& .MuiToolbar-root > .MuiBox-root ~ .MuiButton-root.MuiButton-outlined': {
			alignSelf: 'center',
			backgroundColor: '#fffad9',
			marginLeft: theme.spacing(1),
			'& .MuiTypography-root': {
				...theme.typography.body2,
				fontWeight: 'bold',
			},
		},
		'& .MuiToolbar-root > .MuiBox-root ~ .MuiIconButton-root': {
			alignSelf: 'center',
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
		},
		'& .MuiToolbar-root > .MuiBox-root ~ .MuiButton-root.MuiButton-text .MuiTypography-root': theme.typography.body2,
		'& .MuiPaper-root .MuiBox-root': {
			backgroundPosition: 'top right',
			backgroundRepeat: 'no-repeat',
		},
	},
	drawer: {
		'& .MuiIcon-root': { marginRight: theme.spacing(0.5) },
	},
});

import React from 'react';
import * as Registry from '../../registry';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import getChildren from '../../utils/children';

const NavItemContainer = ({ classes, content }) => <>
	{
		getChildren(
			content.map(node => ({ ...node, classes }))
		)
	}
</>;

export default NavItemContainer;

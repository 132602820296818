import React, { useCallback, useContext, useState, useEffect, useRef } from 'react';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { makeStyles } from '@material-ui/styles';
import { Context } from '../../containers/filter/context';
// import { update } from '../../registry';
import { Context as NavigatorContext } from '../../utils/navigator';

function getFieldValues(fields) {
	let r = [];
	for (let fieldName in fields) {
		for (let selectedItem of fields[fieldName].selected) {
			r.push({ fieldName, selectedItem });
		}
	}
	return r;
}

function getFieldValuesFromQuery(contextKey, query) {
	let q = query.split('&');
	q = q.map(item => item.split('='));
	q = q.map(([ key, ...value ]) => [ key, value.join('=') ].map(decodeURIComponent));
	q = q.map(([ key, value ]) => ({ key, value }));

	let r = [];
	for (let { key, value } of q) {
		if (key.startsWith(`${contextKey}_field_`)) {
			let fieldName = key.substring(`${contextKey}_field_`.length);
			if (fieldName.endsWith('[]')) fieldName = fieldName.substring(0, fieldName.length - 2);
			r.push({ fieldName, selectedItem: value });
		}
	}
	return r;
}

const FilterBar = (props, ref) => {
	const context = useContext(Context);
	const nc = useContext(NavigatorContext);
	const [isNavigating, setNavigating] = useState(false);
	const [currentSort, setCurrentSort] = useState(context.currentSort);
	const [currentTags, setCurrentTags] = useState(context.selectedTags);
	const [currentFields, _setCurrentFields] = useState(() => getFieldValues(context.fields));
	const setCurrentFields = v => {
		console.log(`setCurrentFields: `, v);
		return _setCurrentFields(v);
	}
	
	useEffect(() => {
		setNavigating(!!(context.recordList && context.recordList.isLoading));
	}, [context.recordList, !!(context.recordList && context.recordList.isLoading)]);
	useEffect(() => {
		setCurrentSort(context.currentSort);
	}, [context.currentSort]);
	useEffect(() => {
		setCurrentTags(context.selectedTags);
	}, [context.selectedTags]);
	useEffect(() => {
		setCurrentFields(getFieldValues(context.fields));
	}, [context.fields]);

	let targetSearch = useRef({ navContext: nc, search: typeof location == 'object' ? location.search.substring(1) : '', filterContext: context, wasOpen: context.open });

	useEffect(() => {
		if (!props.inDrawer) {
			targetSearch.current.search = location.search.substring(1);
		}
	}, [typeof location == 'object' ? location.search : '']);
	useEffect(() => {
		if (props.inDrawer) {
			if (!context.open) {
				if (targetSearch.current.wasOpen) {
					let h = location.href.split('?').shift();
					let s = targetSearch.current.search.split('&').filter(v => !v.startsWith(`${context.key}_open=`));
					s = s.join('&');
					if (targetSearch.current.search) h += '?' + s;
					targetSearch.current.navContext.navigate(h);
					targetSearch.current.wasOpen = false;
				}
			} else {
				targetSearch.current.wasOpen = true;
			}
		}
	}, [context.open]);
	useEffect(() => {
		targetSearch.current.navContext = nc;
	}, [nc]);
	useEffect(() => {
		targetSearch.current.filterContext = context;
	}, [context]);
	
	const callback = useCallback(
		(event, value) => {
			let H = targetSearch.current.search;
			H = H.split('&')
				.filter(v => !!v)
				.map(v => v.split('='))
				.map(([a, ...b]) => [a, b.join('=')].map(decodeURIComponent))
				.map(([key, value]) => ({ key, value }));
			const k = `${context.key}_sort`;
			let E = H.find(({ key }) => key == k);
			if (E) E.value = value;
			else H.push({ key: k, value });

			E = H.find(({ key }) => key == k);
			if (E.value == context.defaultSort) H.splice(H.indexOf(E), 1);

			H = H.map(({ key, value }) => [key, value].map(encodeURIComponent).join('=')).join('&');

			let p = location.href.split('?').shift();
			if (H) p += `?${H}`;

			setCurrentSort(value);
			if (props.inDrawer) {
				targetSearch.current.search = H;
			} else {
				setNavigating(true);
				nc.navigate(p);
			}
		},
		[nc]
	);

	const tagCallback = useCallback(
		event => {
			let H = targetSearch.current.search;
			H = H.split('&')
				.filter(v => !!v)
				.map(v => v.split('='))
				.map(([a, ...b]) => [a, b.join('=')].map(decodeURIComponent))
				.map(([key, value]) => ({ key, value }));
			const k = `${context.key}_tag[]`;
			const value = event.target.checked ? event.target.value : '';
			const E = H.find(({ key, value }) => key == k && value == event.target.value);
			if (value) {
				if (!E) H.push({ key: k, value });
			} else if (E) H.splice(H.indexOf(E), 1);

			const H0 = H;
			H = H.map(({ key, value }) => [key, value].map(encodeURIComponent).join('=')).join('&');
			let p = location.href.split('?').shift();
			if (H) p += `?${H}`;
			setCurrentTags(H0.filter(({ key }) => key == k).map(({ value }) => value));
			if (props.inDrawer) {
				targetSearch.current.search = H;
			} else {
				setNavigating(true);
				nc.navigate(p);
			}
		},
		[nc]
	);

	const fieldCallback = useCallback(
		event => {
			let H = targetSearch.current.search;
			H = H.split('&')
				.filter(v => !!v)
				.map(v => v.split('='))
				.map(([a, ...b]) => [a, b.join('=')].map(decodeURIComponent))
				.map(([ key, value ]) => ({ key, value }));
			const k = `${context.key}_field_${event.target.name}[]`;
			const value = event.target.checked ? event.target.value : '';
			const E = H.find(({ key, value }) => key == k && value == event.target.value);
			if (value) {
				if (!E) H.push({ key: k, value });
			} else if (E) {
				H.splice(H.indexOf(E), 1);
			}
	
			const H0 = H;
			H = H.map(({ key, value }) => [key, value].map(encodeURIComponent).join('=')).join('&');
			let p = location.href.split('?').shift();
			if (H) p += `?${H}`;

			setCurrentFields(getFieldValuesFromQuery(context.key, H));
			if (props.inDrawer) {
				targetSearch.current.search = H;
			} else {
				setNavigating(true);
				nc.navigate(p);
			}
		},
		[nc]
	);

	return (
		<div ref={ref} className={props.classes.root}>
			{context.showSort && (
				<FormControl component="fieldset" className={props.classes.fieldset}>
					<FormLabel component="legend">Sort</FormLabel>
					<RadioGroup aria-label="sort order" name="sort" value={currentSort} onChange={callback}>
						{context.showDefault && (
							<FormControlLabel
								value="default"
								control={<Radio />}
								label={context.defaultTitle}
								disabled={isNavigating}
							/>
						)}
						<FormControlLabel value="alphabetical" control={<Radio />} label="A-Z" disabled={isNavigating} />
						<FormControlLabel value="reverseAlphabetical" control={<Radio />} label="Z-A" disabled={isNavigating} />
						<FormControlLabel value="oldest" control={<Radio />} label="Oldest" disabled={isNavigating} />
						<FormControlLabel value="newest" control={<Radio />} label="Newest" disabled={isNavigating} />
					</RadioGroup>
				</FormControl>
			)}
			{context.fields && Object.keys(context.fields).length > 0 && (
				Object.entries(context.fields).map(([fieldName, fieldData], index) => (
					fieldData.values.length > 0 && (
						<FormControl component="fieldset" className={props.classes.fieldset} key={fieldName}>
							<FormLabel component="legend">{fieldData.title}</FormLabel>
							<FormGroup>
								{fieldData.values.map(value => (
									<FormControlLabel 
										key={value}
										control={
											<Checkbox 
												checked={!!currentFields.find(v => v.fieldName == fieldName && v.selectedItem == value)}
												name={fieldName}
												value={value}
												onChange={fieldCallback}
												disabled={isNavigating}
											/>
										}
										label={value}
									/>
								))}
							</FormGroup>
						</FormControl>
					)
				))
			)}
			{context.tags && Object.keys(context.tags).length > 0 && (
				<FormControl component="fieldset" className={props.classes.fieldset}>
					<FormLabel component="legend">Tags</FormLabel>
					<FormGroup>
						{Object.entries(context.tags).map(([key, value]) => (
							<FormControlLabel
								key={key}
								control={
									<Checkbox
										checked={currentTags.indexOf(key) != -1}
										value={key}
										onChange={tagCallback}
										disabled={isNavigating}
									/>
								}
								label={value}
							/>
						))}
					</FormGroup>
				</FormControl>
			)}
		</div>
	);
};

export default ThemeLoader(
	StyleLoader(
		FilterBar,
		makeStyles(
			theme => ({
				root: {
					display: 'flex',
					flexDirection: 'column',
				},
				fieldset: {
					flex: 0,
					'& + $fieldset': {
						marginTop: theme.spacing(2),
					},
				},
			}),
			{ name: 'SwFilterBar' }
		)
	)
);

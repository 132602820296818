import React from 'react';
import Typography from '@material-ui/core/Typography';

const HeaderField = ({ classes, text }) => <Typography className={classes.header} variant="h5">{text}</Typography>;

export default HeaderField;
export const Style = {
	header: {}
};


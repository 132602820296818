import React, { useState, useEffect, useCallback, useReducer, useContext } from 'react';
import ReactDOM from 'react-dom';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
// import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import useBreakpoint from '@sightworks/block/lib/utils/useBreakpoint';
import { QuestionTypes } from '@sightworks/form';
import getChildren from '@sightworks/block/lib/utils/children';
import { Context as ActionContext } from '@sightworks/block/lib/utils/actionContext';
import { useTheme } from '@material-ui/styles';

const Question = props => {
	if (QuestionTypes[props.type]) {
		return React.createElement(QuestionTypes[props.type].field, props);
	}
	return <p>FIXME: {props.type}</p>
}

async function submitForm(url, catalogItem, form, formContents, dispatch, recaptchaResponse) {
	let fc = {};
	for (let [field, value] of Object.entries(formContents)) {
		if (typeof value == 'object' && value && value.getValue) {
			let v = await value.getValue(`${url}/upload/${field}`);
			fc[field] = v;
		} else {
			fc[field] = value;
		}
	}
	let result = await fetch(`${url}/submit`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			catalogItem,
			form,
			answers: fc,
			recaptchaResponse
		})
	});
	if (!(result.status == 200 || result.status == 400)) {
		dispatch({
			action: 'error',
			data: {
				general: true,
				message: 'An error occurred submitting your form.'
			}
		});
		return;
	}

	let body = await result.json();
	if (result.status == 400)
		dispatch({
			action: 'error',
			data: body
		});
	else
		dispatch({
			action: 'success'
		});
}

const FormReducer = (state, { action, data }) => {
	switch (action) {
		case 'setField':
			if (state.status == 'READY') {
				return {
					...state,
					formContents: { ...state.formContents, ...data }
				};
			}
			return state;

		case 'setForm':
			if (state.status == 'LOADING') {
				return {
					...state,
					form: data,
					status: 'READY',
					error: null
				}
			};
			return state;

		case 'submit':
			if (state.status == 'READY') {
				let {
					dispatch,
					responseRoot,
					catalogItem
				} = data;
				let { form, formContents } = state;

				submitForm(responseRoot, catalogItem, form.id, formContents, dispatch, state.recaptchaResponse);
				return {
					...state,
					status: 'SUBMITTING',
					error: null
				}
			}
			return state;

		case 'success':
			if (state.status == 'SUBMITTING') {
				return { ...state, status: 'DONE', error: null };
			}
			return state;

		case 'error':
			if (state.status == 'SUBMITTING') {
				return { ...state, status: 'READY', error: data };
			}
			return state;

		case 'captcha':
			if (state.status != 'SUBMITTING' && state.status != 'DONE') {
				return {
					...state,
					recaptchaResponse: data
				};
			}
			return state;

		default:
			return state;
	}
}

const Form = (props, ref) => {
	let [ state, dispatch ] = useReducer(FormReducer, {
		formContents: {},
		form: {},
		status: 'LOADING',
		error: null
	});

	let actionContext = useContext(ActionContext);

	useEffect(() => {
		fetch(props.endpoints.form + '/form/' + props.form).then(data => data.json()).then(form => dispatch({ action: 'setForm', data: form }));
	}, [ props.endpoints.form, props.form ]);

	let onSubmit = useCallback(event => {
		event.preventDefault();
		dispatch({
			action: 'submit',
			data: {
				responseRoot: props.endpoints.response,
				target: props.endpoints.response + '/submit',
				catalogItem: props.catalogItem,
				dispatch
			}
		});
	}, [ props.endpoints.response, props.catalogItem ]);

	let [captchaNode, setCaptchaNode] = useState(null);
	let theme = useTheme();

	useEffect(() => {
		if (captchaNode) grecaptcha.render(captchaNode, {
			sitekey: props.recaptchaKey,
			theme: theme.palette.type,
			callback: response => {
				dispatch({ action: 'captcha', data: response });
			},
			'expired-callback': () => { dispatch({ action: 'captcha', data: null }); }
		});
	}, [captchaNode]);

	console.log(state);

	if (state.status == 'LOADING') return null;

	let submit;
	if (actionContext.target) {
		submit = (
			<div className={props.classes.submit}>
				{getChildren(
					props.button.map(v => ({ ...v, link: { internal: true, handler: onSubmit } }))
				)}
			</div>
		);
	} else {
		submit = (
			<div className={props.classes.submit}>
				{getChildren(props.button)}
			</div>
		);
	}

	return (
		<form onSubmit={onSubmit} className={props.classes.root}>
			{state.status == 'DONE' && getChildren(props.content)}
			{state.status != 'DONE' && (<>
				{state.error && state.error.general && (
					<Typography className={props.classes.generalError} variant="subtitle1" color="error">
						{state.error.message}
					</Typography>
				)}
				{state.form.questions.map(question => (
					<Question
						error={(state.error && !state.error.general && state.error.question == question.id) ? state.error : null}
						status={state.status} key={question.id} classes={props.classes} dispatch={dispatch} {...question} value={state.formContents[question.id] || ""}
					/>
				))}
				{props.recaptchaKey && (
					<>
						<div className={clsx("g-recaptcha", props.classes.field, props.classes.captchaField)} ref={setCaptchaNode}/>
						{(state.error && state.error.errorCode == 'INVALID_CAPTCHA') ? (
							<Typography variant="subtitle1" className={props.classes.captchaError} color="error">
								Please make sure the reCAPTCHA above is done.
							</Typography>
						) : null}
					</>
				)}
				{actionContext.target ? ReactDOM.createPortal(submit, actionContext.target) : submit}
			</>)}
		</form>
	);
}

export default ThemeLoader(
	StyleLoader(
		Form,
		makeStyles(
			theme => ({
				root: {
					display: 'flex',
					flexDirection: 'column'
				},
				field: {
					marginTop: theme.spacing(1),
					marginBottom: theme.spacing(1)
				},
				captchaError: {
					marginBottom: theme.spacing(1)
				},
				...(Object.keys(QuestionTypes).reduce((acc, type) => ({
					...acc,
					...(typeof QuestionTypes[type].style == 'function' ? QuestionTypes[type].style(theme) : QuestionTypes[type].style)
				}), {})),
				submit: {
					flex: 0,
					marginTop: theme.spacing(1),
					marginRight: 'auto'
				}
			}),
			{ name: 'SWForm' }
		)
	)
);

import React from 'react';
import { StyleLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const RawFreeformBlock = ({ classes, content, useContrast }, ref) => (
	<div
		className={clsx(classes.root, { [classes.contrast]: useContrast })}
		dangerouslySetInnerHTML={{ __html: content }}
		ref={ref}
	/>
);

const Styles = makeStyles(
	theme => ({
		root: {
			margin: 0,
			'& a': {
				textDecoration: 'none',
				color: theme.palette.primary.light,
				transition: theme.transitions.create('color'),
				'&:hover': {
					color: theme.palette.primary.dark,
					textDecoration: 'underline',
				},
				'&.secondary': {
					color: theme.palette.secondary.main,
					'&:hover': {
						color: theme.palette.secondary.dark,
						textDecoration: 'underline',
					},
				},
			},
			'& > :first-child': { marginTop: 0 },
			'& > :last-child': { marginBottom: 0 },
			...theme.typography.body1,
			'& h1, & .h1': theme.typography.h1,
			'& h2, & .h2': theme.typography.h2,
			'& h3, & .h3': theme.typography.h3,
			'& h4, & .h4': theme.typography.h4,
			'& h5, & .h5': theme.typography.h5,
			'& h6, & .h6': theme.typography.h6,
			'& .body1': theme.typography.body1,
			'& .body2': theme.typography.body2,
			'& .caption': theme.typography.caption,
			'& .button': theme.typography.button,
			'& .subtitle1': theme.typography.subtitle1,
			'& .subtitle2': theme.typography.subtitle2,
			'& .overline': theme.typography.overline,
		},
		contrast: {
			color: theme.palette.primary.contrastText,
		},
	}),
	{ name: 'SWFreeform' }
);

export default StyleLoader(RawFreeformBlock, Styles);

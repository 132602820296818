import ThemeLoader from './theme';
import StyleLoader from './style';
import preload from './preload';

const Modules = new Map();
export function getThemeModule(module) {
	return Modules.get(module.moduleId);
}

export async function loadThemeModule(module) {
	const target = getThemeModule(module);
	if (target) return target;

	const modules = document.querySelectorAll('template[data-module-id]');
	let code;

	for (let i = 0; i < modules.length; i++) {
		if (modules[i].dataset.moduleId == module.moduleId) {
			if ('content' in modules[i]) code = modules[i].content.textContent;
			else code = modules[i].textContent;
			break;
		}
	}

	if (code === void 0) {
		let p = document.querySelector('base').getAttribute('href') + module.location;
		p = p.replace(/\/+/g, '/');
		const result = await fetch(p);
		code = await result.text();
	}

	const s = `var ${module.moduleId} =\n`;
	let t = `\nreturn `;
	while (t.length != s.length) t = ` ${t}`;
	code = code.replace(
		/# sourceMappingURL=/,
		`# sourceMappingURL=${document.querySelector('base').getAttribute('href')}public/`
	);
	code = code.replace(s, t);
	const B = Function('', code);
	const R = B();

	Modules.set(module.moduleId, R);
	return R;
}

export { ThemeLoader, StyleLoader, preload };
